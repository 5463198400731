<template>
  <div>
  <div class="mobile-reservation" @scroll="centerScroll($event)" v-if="isMobile">
    <div class="reservation_menu" v-if="disabled">
      <div class="kuang-close" @click="hiddenClick"></div>
      <ul>
        <li :class="{ active: 'center_top'=== currentActive }" @click="activeClass('center_top')">首頁</li>
        <li :class="{ active: 'center_before'=== currentActive }" @click="activeClass('center_before')">事前預約</li>
        <li :class="{ active: 'center_invite'=== currentActive }" @click="activeClass('center_invite')">好友邀請</li>
        <li :class="{ active: 'center_log'=== currentActive }" @click="activeClass('center_log')">修仙日誌</li>
        <li :class="{ active: 'center_game'=== currentActive }" @click="activeClass('center_game')">遊戲特色</li>
        <li @click="showAwardList">領獎紀錄</li>
      </ul>
    </div>
    <div class="reservation_main">
        <div class="reservation_main_top" id="center_top">
          <div class="reservation_main_top_head">
            <div class="logo">
              <img :src="staticUrl+'mobile/reservation/top-icon.png'" alt=""/>
            </div>
            <div class="title">
              <div class="title_text">仙宗大掌門</div>
              <div class="title_desc">修仙模擬經營</div>
            </div>
            <div class="top-btu">
              <a href="javascript:void(0)" @click="activeClass('login_iphone')">
                <img :src="staticUrl+'mobile/reservation/top-btu.png'" alt=""/>
              </a>
            </div>
            <div class="top-kuang" @click="showClick"  v-if="!disabled">
              <img :src="staticUrl+'mobile/reservation/top-kuang.png'" alt=""/>
            </div>
          </div>

          <div class="reservation_main_top_mian">
            <div class="video_btn" @click="tickVideo">
              <img :src="staticUrl+'mobile/reservation/video_btn.png'" alt=""/>
            </div>
            <div class="text_num">
                已經<span class="">{{ appointNum }}</span>人預約
            </div>
            <div class="instant">
              <a class="link-btn google" href="javascript:void(0)" @click="linkToPage('google',links.google)">
                <img :src="staticUrl+'google_play_badge.png'" alt=""/>
              </a>
              <a class="link-btn apple" href="javascript:void(0)" @click="linkToPage('apple',links.apple)" v-if="links.apple">
                <img :src="staticUrl+'app_store.png'" alt=""/>
              </a>
            </div>
            <div class="top-btu" id="center_before">
              <!-- <a class="link-btn book" href="javascript:void(0)" @click="tickBook">
                <img :src="staticUrl+'mobile/reservation/top-btu.png'" alt="" class="iphone ani"/>
              </a> -->
              <a class="link-btn book" href="javascript:void(0)" @click="activeClass('login_iphone')">
                <img :src="staticUrl+'mobile/reservation/top-btu.png'" alt="" class="iphone ani"/>
              </a>
            </div>
          </div>
        </div>

        <div class="reservation_main_instant" id="login_iphone">
          <div class="instant_title_text">
            <img :src="staticUrl+'mobile/reservation/text1.png'"/>
          </div>
          <div class="instant_title_bt">
            <img :src="staticUrl+'mobile/reservation/bt1.png'"/>
          </div>
          <ul class="instant_center">
            <li>
              <img :src="staticUrl+'icon/icon_1.png'" alt="" class="instant_img"/>
              <p class="instant_desc">暗香疏影</p>
              <p class="instant_title">*1</p>
            </li>
            <li>
              <img :src="staticUrl+'icon/icon_2.png'" alt="" class="instant_img"/>
              <p class="instant_desc">綁玉</p>
              <p class="instant_title">*66</p>
            </li>
            <li>
              <img :src="staticUrl+'icon/icon_3.png'" alt="" class="instant_img"/>
              <p class="instant_desc">仙王參</p>
              <p class="instant_title">*1</p>
            </li>
            <li>
              <img :src="staticUrl+'icon/icon_4.png'" alt="" class="instant_img"/>
              <p class="instant_desc">金幣</p>
              <p class="instant_title">*3888</p>
            </li>
            <li>
              <img :src="staticUrl+'icon/icon_5.png'" alt="" class="instant_img"/>
              <p class="instant_desc">尋仙令</p>
              <p class="instant_title">*5</p>
            </li>
          </ul>
          <div class="instant_center_login">
            <!-- <div class="instant_center_login_iphone">+886(台灣)<input type="text" placeholder="請輸入手機號碼" class="code-input"></div> -->
            <div class="book_tips">預約成功後遊戲上市時將發送兌換碼簡訊</div>
            <div class="mobile-form">
              <el-select class="area-select" v-model="mobileAreaCode" @change="changeAreaCode" size="medium" placeholder="請選擇" v-if="!isMobile">
                <el-option
                  v-for="item in mobilePres"
                  :key="'pres-'+item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <select v-model="mobileAreaCode"  @change="changeAreaCode" v-else>
                <option v-for="item in mobilePres"
                  :key="'pres-'+item.value"
                  :value="item.value">{{ item.label }}</option>
              </select>
              <div class="mobile-input-wrap"><input class="mobile-input" type="tel"  :placeholder="mobileHolder" v-model="mobileNum" /></div>
            </div>
            <div class="login-checkbox"><input type="checkbox" value="" @change="checkboxChange">我同意個人資料的收集及接收獎勵簡訊,<a href="/privacy/doc" target="_blank">隱私協議</a></div>
            <a @click="actAppoint()" v-if="!isReservation">
              <img :src="staticUrl+'mobile/reservation/bt1_yuyue_btn.png'" alt="" class="yuyue ani"/>
            </a>
            <img :src="staticUrl+'reservations/yuyue_success.png'" alt="" class="yuyue" v-else/>
          </div>
          <div class="instant_center_step">
            <div class="instant_center_step_left">
              <img :src="staticUrl+'mobile/reservation/step2-icon.png'" />
              <p>按讚抽取</p>
            </div>
            <div class="instant_center_step_right">
              <img :src="staticUrl+'mobile/reservation/step_21.png'" />
              <a :href="links.facebook" target="_blank"><img :src="staticUrl+'mobile/reservation/step2_fb_but.png'" /></a>
            </div>
          </div>
          <div class="instant_center_step">
            <div class="instant_center_step_left">
              <img :src="staticUrl+'mobile/reservation/step3-icon.png'" />
              <p>特典好禮</p>
            </div>
            <div class="instant_center_step_right">
              <img :src="staticUrl+'mobile/reservation/text3.png'" />
              <a href="javascript:void(0)" @click="linkToPage('google',links.google)">
                <img :src="staticUrl+'mobile/reservation/bt3_google_but.png'"  class="google" />
              </a>
              <a href="javascript:void(0)" @click="linkToPage('apple',links.apple)" v-if="links.apple!==''">
                <img :src="staticUrl+'mobile/reservation/bt3_app_but.png'"  class="apple"/>
              </a>
            </div>
          </div>
        </div>

        <div class="reservation_main_invite" id="center_invite">
          <div class="invite_num">
            <ul class="num_center_num">
                <li>3萬</li>
                <li>5萬</li>
                <li>10萬</li>
                <li>15萬</li>
                <li>20萬</li>
            </ul>
            <ul class="num_center_reward">
                <li>
                    <img :src="staticUrl+'mobile/reservation/bt2-reward-img1.png'" alt="" class="goods_img"/>
                    <img :src="staticUrl+'mobile/reservation/select.png'" alt="" class="goods_select" v-if="appointNum>=30000"/>
                    <div class="hover">
                        <div class="hover_content">
                          <p class="hover_title">獎勵內容</p>
                          <p class="hover_desc">金幣*2888<br/>儲物戒*2</p>
                        </div>
                    </div>
                </li>
                <li>
                    <img :src="staticUrl+'mobile/reservation/bt2-reward-img2.png'" alt="" class="goods_img"/>
                    <img :src="staticUrl+'mobile/reservation/select.png'" alt="" class="goods_select" v-if="appointNum>=50000"/>
                    <div class="hover">
                      <div class="hover_content">
                        <p class="hover_title">獎勵內容</p>
                        <p class="hover_desc">進階丹*288<br/>珍禽腿*1</p>
                      </div>
                    </div>
                </li>
                <li>
                    <img :src="staticUrl+'mobile/reservation/bt2-reward-img3.png'" alt="" class="goods_img"/>
                    <img :src="staticUrl+'mobile/reservation/select.png'" alt="" class="goods_select" v-if="appointNum>=100000"/>
                    <div class="hover">
                      <div class="hover_content">
                        <p class="hover_title">獎勵內容</p>
                        <p class="hover_desc">綁玉*88<br/>塵務錄*3</p>
                      </div>
                    </div>
                </li>
                <li>
                    <img :src="staticUrl+'mobile/reservation/bt2-reward-img4.png'" alt="" class="goods_img"/>
                    <img :src="staticUrl+'mobile/reservation/select.png'" alt="" class="goods_select" v-if="appointNum>=150000"/>
                    <div class="hover">
                      <div class="hover_content">
                        <p class="hover_title">獎勵內容</p>
                        <p class="hover_desc">招募令*10<br/>淬鐵石*2</p>
                      </div>
                    </div>
                </li>
                <li>
                    <img :src="staticUrl+'mobile/reservation/bt2-reward-img5.png'" alt="" class="goods_img"/>
                    <img :src="staticUrl+'mobile/reservation/select.png'" alt="" class="goods_select" v-if="appointNum>=200000"/>
                    <div class="hover">
                      <div class="hover_content">
                        <p class="hover_title">獎勵內容</p>
                        <p class="hover_desc">尋仙令*10<br/>點金石*1</p>
                      </div>
                    </div>
                </li>
            </ul>
          </div>
          <div class="reservation_main_img"><img :src="staticUrl+'mobile/reservation/bt3.png'" alt=""/></div>
          <div class="invite_center">
            <div class="invite_center_item">
              <div class="title">邀請<span>1</span>人獎勵</div>
              <div class="desc">
                <div>
                  <img :src="staticUrl+'icon/bt3-icon1.png'" alt=""/><br>
                  <p>金幣*3888</p>
                </div>
                <div>
                  <img :src="staticUrl+'icon/bt3-icon2.png'" alt=""/><br>
                  <p>尋仙令*1</p>
                </div>
                <div>
                  <img :src="staticUrl+'icon/bt3-icon3.png'" alt=""/><br>
                  <p>招募令*5</p>
                </div>
              </div>
              <div class="btn">
                <button type="button" @click="getAwardProp(3)" class="yilingqu" v-if="isInvteProp3===2">已領取</button>
                <button type="button" @click="getAwardProp(3)" class="nolingqu" v-else-if="isInvteProp3===1">領取獎勵</button>
                <button type="button" @click="getAwardProp(3)" class="nojiesuo" v-else>未成功邀請</button>
              </div>
              <div class="btn2">
                <button type="button" @click="copyUrl()" class="copy_but" v-if="isReservation && this.userInfo.token">複製連結</button>
                <button type="button" @click="checkFbLogin()" class="fb_but" v-else-if="isReservation && this.userInfo.token===''">FB登入</button>
                <button type="button" @click="tickBook" class="yuyue_but" v-else>立即預約</button>
              </div>
            </div>
            <div class="invite_center_item invite_center_item2">
              <div class="title">邀請<span>3</span>人獎勵</div>
              <div class="desc">
                <div>
                  <img :src="staticUrl+'icon/bt3-icon4.png'" alt=""/><br>
                  <p>綁玉*68</p>
                </div>
                <div>
                  <img :src="staticUrl+'icon/bt3-icon2.png'" alt=""/><br>
                  <p>尋仙令*5</p>
                </div>
                <div>
                  <img :src="staticUrl+'icon/bt3-icon5.png'" alt=""/><br>
                  <p>進階丹*288</p>
                </div>
              </div>
              <div class="btn">
                <button type="button" @click="getAwardProp(4)" class="yilingqu" v-if="isInvteProp4===2">已領取</button>
                <button type="button" @click="getAwardProp(4)" class="nolingqu" v-else-if="isInvteProp4===1">領取獎勵</button>
                <button type="button" @click="getAwardProp(4)" class="nojiesuo" v-else>未成功邀請</button>
              </div>
              <div class="btn2">
                <button type="button" @click="isShowAwardRule=true" class="rule_but">活動規則</button>
              </div>
            </div>
          </div>
        </div>

        <div class="reservation_main_game" id="center_log">
          <div class="reservation_main_img">
            <img :src="staticUrl+'mobile/reservation/bt4.png'" alt=""/>
          </div>
          <div class="log_center">
            <a  href="javascript:void(0)" @click="showAwardQuestion" v-if="isReservation && this.userInfo.token">
              <img  class="question-btn ani" :src="staticUrl+'reservations/bt4_but.png'" alt=""/>
            </a>
            <a  href="javascript:void(0)" @click="checkFbLogin" v-else-if="isReservation && this.userInfo.token===''">
              <img  class="question-btn ani" :src="staticUrl+'reservations/bt4_but_fb.png'" alt=""/>
            </a>
            <a  href="javascript:void(0)" @click="tickBook" v-else>
              <img  class="question-btn ani" :src="staticUrl+'reservations/bt4_but_yuyue.png'" alt=""/>
            </a>
          </div>
          <div class="log_center_tip">活動規則：參與修仙日誌小測試，測一測你的修仙結局吧~參與即可獲得一份修仙獎勵喲~</div>

          <div class="reservation_main_img" id="center_game">
            <img :src="staticUrl+'mobile/reservation/bt5.png'" alt=""/>
          </div>
          <div class="game_center">
            <div class="game_center_prev"><img :src="staticUrl+'mobile/reservation/prev_but.png'"></div>
            <div class="game_center_swiper_swiping">
              <swiper :options="swiperOptions">
                <swiper-slide v-for="(item,index) in swipingData" :key="index">
                  <img :src="item.mobile">
                </swiper-slide>
              </swiper>
            </div>
            <div class="game_center_next"><img :src="staticUrl+'mobile/reservation/next_but.png'"></div>
          </div>
          <div class="game_pagination" slot="pagination"></div>
          <div class="game_link">
            <h4>注意事項</h4>
            <p>1.事前預約活動時間為 即日起至遊戲開服前一天的23:59。</p>
            <p>2.每個Facebook帳號、手機號碼，僅限參加活動一次，每項獎勵僅限領取一次。如因個人資料輸入錯誤導致獎勵無法正確發送，恕不補發。</p>
            <p>3.手機預約獎勵，將在公測後透過手機簡訊發放，請密切留意通知簡訊。獎勵序號僅限每個角色兌換1次，一經兌換，該序號即視爲失效。兌換期限至2023/1/31 23:59。</p>
            <p>4.預約人數達指定目標獎勵，將在公測後7天內可通過遊戲内領取。(範例：預約人數如達20萬，則全服可領取到3、5、10、15、20萬人的獎勵)</p>
            <p>5.實體獎勵將於活動結束後統一寄送，煩請密切留意領獎通知簡訊。</p>
            <p>6.主辦單位將以手機簡訊主動通知實體獎得獎者，得獎者需於收到通知5天內回覆領獎憑證，方可獲得贈品，逾期視同放棄領獎資格 。</p>
            <p>7.主辦單位將於收回領據後寄出贈品，得獎者若於寄出後20日內尚未收到獎品，煩請主動通知主辦單位，逾期視同放棄領獎資格。實物獎項以實物為準，限得獎者本人領取，且不得要求折抵現金、轉讓他人領獎或兌換其他獎項或優惠。</p>
            <p>8.依稅法規定，得獎價值價值超過新台幣20,000元以上，得獎者須自行負擔贈品稅，得獎者之獎品價值超過新台幣1,000元（含）以上者，須提供身份證正、反面影本，以利廠商申報中獎扣繳憑單。</p>
            <p>9.主辦單位保留變更或終止本活動之權利，變更內容將於活動網站調整，恕不另行通知。</p>
          </div>
        </div>

        <div class="reservation_foot">
          <a :href="links.discord" target="_blank"><img :src="staticUrl+'foot_discord.png'" class="reservation_foot_small"/></a>
          <a :href="links.facebook" target="_blank"><img :src="staticUrl+'mobile/reservation/foot_fb.png'" class="reservation_foot_large"/></a>
        </div>
    </div>

    <ActLogin  @onClose='isShowActLogin=false' v-if="isShowActLogin"/>
    <AwardList  @onClose='isShowAwardList=false' v-if="isShowAwardList" />
    <AwardRule  @onClose='isShowAwardRule=false' v-if="isShowAwardRule" />
    <AwardSuccess  @onClose='isShowAwardSuccess=false' v-if="isShowAwardSuccess" />
    <AwardProp  :propId="awardPropId" @onClose='isShowAwardProp=false' v-if="isShowAwardProp" />
    <AwardQuestion  @onClose='isShowAwardQuestion=false' v-if="isShowAwardQuestion" />
    <VideoPlay :src="videoUrl" :controls="isControls" @onClose='isShowVideoPlay=false' v-if="isShowVideoPlay" />
  </div>
  <div class="reservation" @scroll="centerScroll($event)" v-else>
    <div class="reservation_top">
      <div class="reservation_top_left" v-show="showLeft">
        <div class="reservation_top_left_title">
          <ul>
            <li :class="{ active: 'center_top'=== currentActive }" @click="activeClass('center_top')">首頁</li>
            <li :class="{ active: 'center_before'=== currentActive }" @click="activeClass('center_before')">事前預約</li>
            <li :class="{ active: 'center_invite'=== currentActive }" @click="activeClass('center_invite')">好友邀請</li>
            <li :class="{ active: 'center_log'=== currentActive }" @click="activeClass('center_log')">修仙日誌</li>
            <li :class="{ active: 'center_game'=== currentActive }" @click="activeClass('center_game')">遊戲特色</li>
          </ul>
        </div>
      </div>
      <div class="reservation_top_center" id="center_top">
        <div class="logo">
          <img :src="staticUrl+'reservations/logo.png'" alt=""/>
        </div>
        <div class="text">
          <div class="text_num">
            已經<span class="">{{ appointNum }}</span>人預約
          </div>
          <div class="text_btn">
            <a @click="tickVideo"><img :src="staticUrl+'reservations/video_btn.png'" alt=""/></a>
          </div>
        </div>
        <div class="link">
          <a class="link-btn google" href="javascript:void(0)" @click="linkToPage('google',links.google)"><img :src="staticUrl+'google_play_badge.png'" alt=""/></a>
          <a class="link-btn apple" href="javascript:void(0)" @click="linkToPage('apple',links.apple)" v-if="links.apple"><img :src="staticUrl+'app_store.png'" alt=""/></a>
          <!-- <a class="link-btn book" href="javascript:void(0)" @click="tickBook">
            <img :src="staticUrl+'reservations/iphone.png'" alt="" class="iphone ani"/>
          </a> -->
          <a class="link-btn book" href="javascript:void(0)" @click="activeClass('login_iphone')"><img :src="staticUrl+'reservations/iphone.png'" alt="" class="iphone ani"/></a>
        </div>
      </div>
      <div class="reservation_top_right">
        <div class="reservation_top_right_center">
          <a href="javascript:void(0)" @click="linkToPage('google',links.google)"><img :src="staticUrl+'google_play_badge_small.png'" alt=""/></a>
          <a href="javascript:void(0)" @click="linkToPage('apple',links.apple)" v-if="links.apple"><img :src="staticUrl+'pop_app_store_small.png'" alt=""/></a>
          <a :href="links.discord" target="_blank"><img :src="staticUrl+'pop_discord.png'" alt=""/></a>
          <a :href="links.facebook" target="_blank"><img :src="staticUrl+'pop_fb.png'" alt=""/></a>
          <a @click="showAwardList" href="javascript:void(0)"><img :src="staticUrl+'reservations/pop_reward.png'" alt=""/></a>
        </div>
      </div>
    </div>
    <div class="reservation_main">
        <div class="reservation_main_instant" id="center_instant">
          <div class="reservation_main_img"><img :src="staticUrl+'reservations/bt1.png'" alt=""/></div>
          <ul class="instant_center">
            <li>
              <img :src="staticUrl+'icon/icon_1.png'" alt="" class="instant_img"/>
              <p class="instant_title">暗香疏影*1</p>
              <!-- <p class="instant_title">預約即得</p> -->
            </li>
            <li>
              <img :src="staticUrl+'icon/icon_2.png'" alt="" class="instant_img"/>
              <p class="instant_title">綁玉*66</p>
              <!-- <p class="instant_title">預約即得</p> -->
            </li>
            <li>
              <img :src="staticUrl+'icon/icon_3.png'" alt="" class="instant_img"/>
              <p class="instant_title">仙王參*1</p>
              <!-- <p class="instant_title">預約即得</p> -->
            </li>
            <li>
              <img :src="staticUrl+'icon/icon_4.png'" alt="" class="instant_img"/>
              <p class="instant_title">金幣*3888</p>
              <!-- <p class="instant_title">預約即得</p> -->
            </li>
            <li>
              <img :src="staticUrl+'icon/icon_5.png'" alt="" class="instant_img"/>
              <p class="instant_title">尋仙令*5</p>
              <!-- <p class="instant_title">預約即得</p> -->
            </li>
          </ul>
        </div>

        <div class="reservation_main_before" id="center_before" ref="center_before">
          <div class="reservation_main_img"><img :src="staticUrl+'reservations/bt2.png'" alt=""/></div>
          <div class="before_center">
            <div class="before_center_left">
              <div class="before_center_left_step">
                <div class="before_center_left_step_img">
                  <img :src="staticUrl+'reservations/step-icon.png'" alt=""/>
                  <p>預約即得</p>
                </div>

                <div class="before_center_left_step_desc" id="login_iphone">
                  <img :src="staticUrl+'reservations/text1.png'" class="step_title"/>
                  <div class="book_tips">預約成功後遊戲上市時將發送兌換碼簡訊</div>
                  <div class="mobile-form">
                    <el-select class="area-select" v-model="mobileAreaCode" @change="changeAreaCode" size="medium" placeholder="請選擇" v-if="!isMobile">
                      <el-option
                        v-for="item in mobilePres"
                        :key="'pres-'+item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                    <select v-model="mobileAreaCode"  @change="changeAreaCode" v-else>
                      <option v-for="item in mobilePres"
                        :key="'pres-'+item.value"
                        :value="item.value">{{ item.label }}</option>
                    </select>
                    <div class="mobile-input-wrap"><input class="mobile-input" type="tel"  :placeholder="mobileHolder" v-model="mobileNum" /></div>
                  </div>

                  <div class="login-checkbox">
                    <input type="checkbox" value="" @change="checkboxChange">我同意個人資料的收集及接收獎勵簡訊,<a href="/privacy" target="_blank">隱私協議</a>
                  </div>
                  <a @click="actAppoint()" v-if="!isReservation"><img :src="staticUrl+'reservations/step1-but.png'" alt="" class="yuyue ani"/></a>
                  <img :src="staticUrl+'reservations/yuyue_success.png'" alt="" class="yuyue" v-else/>
                </div>
              </div>
              <div class="before_center_left_step">
                <div class="before_center_left_step_img">
                  <img :src="staticUrl+'reservations/step2-icon.png'"/>
                  <p>預約即得</p>
                </div>
                <div class="before_center_left_step_desc">
                  <img :src="staticUrl+'reservations/text2.png'" class="step_title"/>
                  <a :href="links.facebook" target="_blank"><img :src="staticUrl+'fb_butten.png'" alt=""/></a>
                </div>
              </div>
              <div class="before_center_left_step">
                <div class="before_center_left_step_img pointer" @click="getAwardProp(2)">
                  <img :src="staticUrl+'reservations/step3-icon1.png'"/>
                  <p>預約即得</p>
                </div>
                <div class="before_center_left_step_desc">
                  <img :src="staticUrl+'reservations/text3.png'" class="step_title"/>
                  <a href="javascript:void(0)" @click="linkToPage('google',links.google)"><img class="google" :src="staticUrl+'google_play_badge_small.png'" style="padding-right: 35px;"/></a>
                  <a href="javascript:void(0)" @click="linkToPage('apple',links.apple)" v-if="links.apple!==''"><img class="apple" :src="staticUrl+'pop_app_store_small.png'" alt=""/></a>
                </div>
              </div>
            </div>
            <div class="before_center_right" >
              <img :src="staticUrl+'reservations/step1-right.png'" alt=""/>
              <br/>
              <p>註：抽獎獎勵僅限完成所有預約活動才符合抽獎資格</p>
            </div>
          </div>
          <div class="reservation_main_num_center">
            <ul class="num_center_num">
                <li>3萬</li>
                <li>5萬</li>
                <li>10萬</li>
                <li>15萬</li>
                <li>20萬</li>
            </ul>
            <ul class="num_center_reward">
                <li>
                    <img :src="staticUrl+'reservations/bt2-reward-img1.png'" alt="" class="goods_img"/>
                    <img :src="staticUrl+'reservations/select.png'" alt="" class="goods_select" v-if="appointNum>=30000"/>
                    <div class="hover">
                        <div class="hover_content">
                          <p class="hover_title">獎勵內容</p>
                          <p class="hover_desc">金幣*2888<br/>儲物戒*2</p>
                        </div>
                    </div>
                </li>
                <li>
                    <img :src="staticUrl+'reservations/bt2-reward-img2.png'" alt="" class="goods_img"/>
                    <img :src="staticUrl+'reservations/select.png'" alt="" class="goods_select" v-if="appointNum>=50000"/>
                    <div class="hover">
                      <div class="hover_content">
                        <p class="hover_title">獎勵內容</p>
                        <p class="hover_desc">進階丹*288<br/>珍禽腿*1</p>
                      </div>
                    </div>
                </li>
                <li>
                    <img :src="staticUrl+'reservations/bt2-reward-img3.png'" alt="" class="goods_img"/>
                    <img :src="staticUrl+'reservations/select.png'" alt="" class="goods_select" v-if="appointNum>=100000"/>
                    <div class="hover">
                      <div class="hover_content">
                        <p class="hover_title">獎勵內容</p>
                        <p class="hover_desc">綁玉*88<br/>塵務錄*3</p>
                      </div>
                    </div>
                </li>
                <li>
                    <img :src="staticUrl+'reservations/bt2-reward-img4.png'" alt="" class="goods_img"/>
                    <img :src="staticUrl+'reservations/select.png'" alt="" class="goods_select" v-if="appointNum>=150000"/>
                    <div class="hover">
                      <div class="hover_content">
                        <p class="hover_title">獎勵內容</p>
                        <p class="hover_desc">招募令*10<br/>淬鐵石*2</p>
                      </div>
                    </div>
                </li>
                <li>
                    <img :src="staticUrl+'reservations/bt2-reward-img5.png'" alt="" class="goods_img"/>
                    <img :src="staticUrl+'reservations/select.png'" alt="" class="goods_select" v-if="appointNum>=200000"/>
                    <div class="hover">
                      <div class="hover_content">
                        <p class="hover_title">獎勵內容</p>
                        <p class="hover_desc">尋仙令*10<br/>點金石*1</p>
                      </div>
                    </div>
                </li>
            </ul>
          </div>
        </div>
        <div class="reservation_main_invite" id="center_invite">
          <div class="reservation_main_img"><img :src="staticUrl+'reservations/bt3.png'" alt=""/></div>
          <div class="invite_center">
            <div class="invite_center_item">
              <div class="title">邀請<span>1</span>人獎勵</div>
              <div class="desc">
                <div>
                  <img :src="staticUrl+'icon/bt3-icon1.png'" alt=""/><br>
                  <span>金幣*3888</span>
                </div>
                <div>
                  <img :src="staticUrl+'icon/bt3-icon2.png'" alt=""/><br>
                  <span>尋仙令*1</span>
                </div>
                <div>
                  <img :src="staticUrl+'icon/bt3-icon3.png'" alt=""/><br>
                  <span>招募令*5</span>
                </div>
              </div>
              <div class="btn">
                <button type="button" @click="getAwardProp(3)" class="yilingqu" v-if="isInvteProp3===2">已領取</button>
                <button type="button" @click="getAwardProp(3)" class="nolingqu" v-else-if="isInvteProp3===1">領取獎勵</button>
                <button type="button" @click="getAwardProp(3)" class="nojiesuo" v-else>未成功邀請</button>
              </div>
              <div class="btn2">
                <button type="button" @click="copyUrl()" class="copy_but" v-if="isReservation && this.userInfo.token">複製連結</button>
                <button type="button" @click="checkFbLogin()" class="fb_but" v-else-if="isReservation && this.userInfo.token===''">FB登入</button>
                <button type="button" @click="tickBook" class="yuyue_but" v-else>立即預約</button>
              </div>
            </div>
            <div class="invite_center_item invite_center_item2">
              <div class="title">邀請<span>3</span>人獎勵</div>
              <div class="desc">
                <div>
                  <img :src="staticUrl+'icon/bt3-icon4.png'" alt=""/><br>
                  <span>綁玉*68</span>
                </div>
                <div>
                  <img :src="staticUrl+'icon/bt3-icon2.png'" alt=""/><br>
                  <span>尋仙令*5</span>
                </div>
                <div>
                  <img :src="staticUrl+'icon/bt3-icon5.png'" alt=""/><br>
                  <span>進階丹*288</span>
                </div>
              </div>
              <div class="btn">
                <button type="button" @click="getAwardProp(4)" class="yilingqu" v-if="isInvteProp4===2">已領取</button>
                <button type="button" @click="getAwardProp(4)" class="nolingqu" v-else-if="isInvteProp4===1">領取獎勵</button>
                <button type="button" @click="getAwardProp(4)" class="nojiesuo" v-else>未成功邀請</button>
              </div>
              <div class="btn2">
                <button type="button" @click="isShowAwardRule=true" class="rule_but">活動規則</button>
              </div>
            </div>
          </div>
        </div>

        <div class="reservation_main_log" id="center_log">
          <div class="reservation_main_img"><img :src="staticUrl+'reservations/bt4.png'" alt=""/></div>
          <div class="log_center">
            <a  href="javascript:void(0)" @click="showAwardQuestion" v-if="isReservation && this.userInfo.token">
              <img  class="question-btn ani" :src="staticUrl+'reservations/bt4_but.png'" alt=""/>
            </a>
            <a  href="javascript:void(0)" @click="checkFbLogin" v-else-if="isReservation && this.userInfo.token===''">
              <img  class="question-btn ani" :src="staticUrl+'reservations/bt4_but_fb.png'" alt=""/>
            </a>
            <a  href="javascript:void(0)" @click="tickBook" v-else>
              <img  class="question-btn ani" :src="staticUrl+'reservations/bt4_but_yuyue.png'" alt=""/>
            </a>
          </div>
          <div class="log_center_tip">活動規則：參與修仙日誌小測試，測一測你的修仙結局吧~參與即可獲得一份修仙獎勵喲~</div>
        </div>

        <div class="reservation_main_game" id="center_game">
          <div class="reservation_main_img"><img :src="staticUrl+'reservations/bt5.png'" alt=""/></div>
          <div class="game_center">
            <div class="game_center_prev"><img :src="staticUrl+'reservations/prev_but.png'"></div>
            <div class="game_center_swiper_swiping">
              <swiper :options="swiperOptions">
                <swiper-slide v-for="(item,index) in swipingData" :key="index">
                  <img :src="item.image">
                </swiper-slide>
              </swiper>
            </div>
            <div class="game_center_next"><img :src="staticUrl+'reservations/next_but.png'"></div>
          </div>
          <div class="game_pagination" slot="pagination"></div>
        </div>

        <div class="reservation_main_link">
          <h4>注意事項</h4>
          <p>1.事前預約活動時間為 即日起至遊戲開服前一天的23:59。</p>
          <p>2.每個Facebook帳號、手機號碼，僅限參加活動一次，每項獎勵僅限領取一次。如因個人資料輸入錯誤導致獎勵無法正確發送，恕不補發。</p>
          <p>3.手機預約獎勵，將在公測後透過手機簡訊發放，請密切留意通知簡訊。獎勵序號僅限每個角色兌換1次，一經兌換，該序號即視爲失效。兌換期限至2023/1/31 23:59。</p>
          <p>4.預約人數達指定目標獎勵，將在公測後7天內可通過遊戲内領取。(範例：預約人數如達20萬，則全服可領取到3、5、10、15、20萬人的獎勵)</p>
          <p>5.實體獎勵將於活動結束後統一寄送，煩請密切留意領獎通知簡訊。</p>
          <p>6.主辦單位將以手機簡訊主動通知實體獎得獎者，得獎者需於收到通知5天內回覆領獎憑證，方可獲得贈品，逾期視同放棄領獎資格 。</p>
          <p>7.主辦單位將於收回領據後寄出贈品，得獎者若於寄出後20日內尚未收到獎品，煩請主動通知主辦單位，逾期視同放棄領獎資格。實物獎項以實物為準，限得獎者本人領取，且不得要求折抵現金、轉讓他人領獎或兌換其他獎項或優惠。</p>
          <p>8.依稅法規定，得獎價值價值超過新台幣20,000元以上，得獎者須自行負擔贈品稅，得獎者之獎品價值超過新台幣1,000元（含）以上者，須提供身份證正、反面影本，以利廠商申報中獎扣繳憑單。</p>
          <p>9.主辦單位保留變更或終止本活動之權利，變更內容將於活動網站調整，恕不另行通知。</p>
        </div>
    </div>

    <ActLogin  @onClose='isShowActLogin=false' v-if="isShowActLogin"/>
    <AwardList  @onClose='isShowAwardList=false' v-if="isShowAwardList" />
    <AwardRule  @onClose='isShowAwardRule=false' v-if="isShowAwardRule" />
    <AwardSuccess  @onClose='isShowAwardSuccess=false' v-if="isShowAwardSuccess" />
    <AwardProp  :propId="awardPropId" @onClose='isShowAwardProp=false' v-if="isShowAwardProp" />
    <AwardQuestion  @onClose='isShowAwardQuestion=false' @onProp="onQuestionProp" v-if="isShowAwardQuestion" />
    <VideoPlay :src="videoUrl" :controls="isControls" @onClose='isShowVideoPlay=false' v-if="isShowVideoPlay" />
  </div>
  <foot-bar v-if="!isMobile"/>
</div>
</template>

<script>
import { FootBar } from '@/components'
import ActLogin from '@/components/reservation/ActLogin'
import AwardList from '@/components/reservation/AwardList'
import AwardRule from '@/components/reservation/AwardRule'
import AwardSuccess from '@/components/reservation/AwardSuccess'
import AwardProp from '@/components/reservation/AwardProp'
import AwardQuestion from '@/components/reservation/AwardQuestion'
import VideoPlay from '@/components/reservation/VideoPlay'
import { mapGetters, mapActions } from 'vuex'
import utils from '@/utils/utils'

export default {
  name: 'reservationPc',
  components: {
    ActLogin,
    AwardList,
    AwardRule,
    AwardSuccess,
    AwardProp,
    AwardQuestion,
    VideoPlay,
    FootBar
  },
  computed: {
    ...mapGetters([
      'userInfo',
      'links',
      'staticUrl',
      'isAppStore',
      'videoUrl',
      'isMobile',
      'isReservation',
    ])
  },
  watch: {
    mobileNum (value) {
      if (value.length > 0) {
        this.mobileNum = value.replace(/[^0-9]/, '')
        switch (this.mobileAreaCode) {
          case '+886':
            if (value[0] !== '9') {
              this.mobileNum = ''
            }
            if (value.length > 9) {
              this.mobileNum = value.substr(0, 9)
            }
            break;
          case '+852':
            if (value.length > 8) {
              this.mobileNum = value.substr(0, 8)
            }
            break;
          case '+853':
            if (value[0] !== '6') {
              this.mobileNum = ''
            }
            if (value.length > 8) {
              this.mobileNum = value.substr(0, 8)
            }
            break;
        }
      }
    }
  },
  data () {
    return {
      isCheck: false,
      mobilePres: [{
        value: '+886',
        label: '+886(台灣)'
      }, {
        value: '+852',
        label: '+852(香港)'
      }, {
        value: '+853',
        label: '+853(澳門)'
      }],
      mobileAreaCode: '+886',
      mobileHolder: '9xxxxxxxx(共9位)',
      mobileNum: '',
      showLeft: false,
      isControls: true,
      isShowVideoPlay: false,
      disabled: 0,
      step2Click: false,
      selectIndex: 3,
      isShowActLogin: false,
      isShowAwardList: false,
      isShowAwardRule: false,
      isShowAwardSuccess: false,
      isShowAwardProp: false,
      isShowAwardQuestion: false,
      awardPropId: '',
      inviteNum: 0,
      appointNum: 0,
      isInvteProp3: 0,
      isInvteProp4: 0,
      shareUrl: 'https://www.kaguyajoy.com.tw/reservation',
      currentActive: 'center_top',
      show: false,
      swipingData: [
        // eslint-disable-next-line no-undef
        { name: '1', image: staticUrl + 'reservations/img-1.png', mobile: staticUrl + 'mobile/reservation/img-1.png' },
        // eslint-disable-next-line no-undef
        { name: '2', image: staticUrl + 'reservations/img-2.png', mobile: staticUrl + 'mobile/reservation/img-2.png' },
        // eslint-disable-next-line no-undef
        { name: '3', image: staticUrl + 'reservations/img-3.png', mobile: staticUrl + 'mobile/reservation/img-3.png' },
        // eslint-disable-next-line no-undef
        { name: '4', image: staticUrl + 'reservations/img-4.png', mobile: staticUrl + 'mobile/reservation/img-4.png' },
        // eslint-disable-next-line no-undef
        { name: '5', image: staticUrl + 'reservations/img-5.png', mobile: staticUrl + 'mobile/reservation/img-5.png' },
        // eslint-disable-next-line no-undef
        { name: '6', image: staticUrl + 'reservations/img-6.png', mobile: staticUrl + 'mobile/reservation/img-6.png' },
        // eslint-disable-next-line no-undef
      ],
      swiperOptions: {
        centeredSlides: true,
        spaceBetween: 30,
        autoplay: {
          delay: 5000,
          disableOnInteraction: true
        },
        pagination: {
          el: '.game_pagination',
          type: 'bullets',
          clickable: false,
          progressbarOpposite: true,
        },
        loop: true,
        navigation: {
          nextEl: '.game_center_next',
          prevEl: '.game_center_prev'
        }
      },
      lockAppoint: false,
    }
  },
  mounted () {
    document.addEventListener('scroll', this.centerScroll, true)
    this.saveQuery()
    if (this.$route.name === 'reservationSuccess' || this.$route.name === 'reservationOk') {
      this.isShowAwardSuccess = true
    } else {
      if (!this.isReservation) {
        this.isShowActLogin = true
      } else {
        this.showPropbyUrl()
        this.loadInviteNum()
      }
    }
    this.loadData()
  },
  created () {
    console.log(this.isReservation)
    console.log(this.userInfo)
  },
  methods: {
    ...mapActions(['ActAppoint', 'ActReservation', 'ActAppointNum', 'ActInviteNum', 'ActCodeList', 'ActAppStore', 'ActReward', 'ActSetInvite', 'ActLoginFb']),
    onQuestionProp () {
      this.isShowAwardQuestion = false
      this.awardPropId = '5'
      this.isShowAwardProp = true
    },
    centerScroll () {
      var scrollVals = [{ top: 0, id: 'center_top' }, { top: 100, id: 'center_before' }, { top: 200, id: 'center_invite' }, { top: 300, id: 'center_log' }, { top: 400, id: 'center_game' }];
      scrollVals[0].top = document.querySelector('#center_top').getBoundingClientRect().top
      scrollVals[1].top = document.querySelector('#center_before').getBoundingClientRect().top
      scrollVals[2].top = document.querySelector('#center_invite').getBoundingClientRect().top
      scrollVals[3].top = document.querySelector('#center_log').getBoundingClientRect().top
      scrollVals[4].top = document.querySelector('#center_game').getBoundingClientRect().top
      let minTopArr = this.topSort(scrollVals, 'top')
      if (this.currentActive !== minTopArr[0].id) {
        this.showLeft = true
        this.currentActive = minTopArr[0].id
      }
      if (this.currentActive === 'center_top') {
        this.showLeft = false
      }
    },
    topSort (objArr, key) {
      let result = objArr.slice(0);
      return result.sort((a, b) => Math.abs(a[key]) - Math.abs(b[key]));
    },
    changeAreaCode () {
      switch (this.mobileAreaCode) {
        case '+886':
          this.mobileHolder = '9xxxxxxxx(共9位)'
          break;
        case '+852':
          this.mobileHolder = 'xxxxxxxx(共8位)'
          break;
        case '+853':
          this.mobileHolder = '6xxxxxxx(共8位)'
          break;
      }
      this.mobileNum = ''
    },
    checkboxChange (e) {
      this.isCheck = e.target.checked
    },
    actAppoint () {
      if (this.mobileNum.length < 8 || this.mobileNum.length > 9) {
        this.showError('請輸入正確手機號')
        return;
      }
      if (!this.isCheck) {
        this.showError('請確認隱私協議選項內容')
        return;
      }
      let mobile = this.mobileAreaCode + this.mobileNum;

      let isFacebook = false;
      let isGoogle = false;
      let isTwitter = false;
      let isTiktok = false;
      let utmSource = '';
      let os = utils.getSystemInfo()
      if (typeof (this.$route.query) !== 'undefined' && typeof (this.$route.query['utm_source']) !== 'undefined') {
        if (this.$route.query['utm_source'] === 'facebook') {
          isFacebook = true
        } else if (this.$route.query['utm_source'] === 'google') {
          isGoogle = true;
        } else if (this.$route.query['utm_source'] === 'twitter') {
          isTwitter = true;
        } else if (this.$route.query['utm_source'] === 'tiktok') {
          isTiktok = true;
        }
        utmSource = this.$route.query['utm_source'];
      }

      if (this.lockAppoint) {
        return 
      }
      this.lockAppoint = true
      this.ActAppoint({ mobile: mobile, utm_source: utmSource, os: os }).then((res) => {
        this.lockAppoint = false
        if (res.is_first_appoint === 1) {
          if (isFacebook) {
            window.fbq('track', 'Schedule', { mobile: mobile }, { eventID: mobile });
          }
          if (isGoogle) {
            window.gtag('event', 'conversion', { 'send_to': 'AW-10946335345/7VUzCLbuv4MYEPGkz-Mo' });
          }
          if (isTwitter) {
            window.twq('event', 'tw-ocxth-oczqb', { conversion_id: mobile });
          }
          if (isTiktok) {
            window.ttq.track('AddToWishlist', { event_id: mobile }) 
          }
        }
        this.ActReservation({ mobile: mobile })
        if (res.is_first_appoint === 0) {
          this.showSuccess('該手機號已經成功預約，請勿重複預約！')
        } else {
          this.$router.push({ name: 'reservationSuccess' })
        }
      }).catch((err) => {
        console.log(err)
        this.lockAppoint = false
        this.showSuccess('預約失敗')
      })
    },
    saveQuery () {
      if (typeof (this.$route.query) !== 'undefined') {
        if (typeof (this.$route.query['fromuid']) !== 'undefined') {
          this.ActSetInvite({ invite_uid: parseInt(this.$route.query['fromuid']) })
        }
      }
    },
    tickBook () {
      if (!this.isReservation) {
        this.isShowActLogin = true
      } else {
        this.isShowAwardSuccess = true
      }
    },
    showPropbyUrl () {
      if (typeof (this.$route.query) !== 'undefined') {
        if (typeof (this.$route.query['prop']) !== 'undefined') {
          this.awardPropId = this.$route.query['prop'] + ''
          this.isShowAwardProp = true
        }
      }
    },
    getAwardProp (id) {
      if (id === 2) {
        if (this.isAppStore === 1) {
          this.ActReward({ code_type: 2 }).then(res => {
            this.awardPropId = '2'
            this.isShowAwardProp = true
          })
        } else {
          this.showError('請到商店預約');
        }
      } else if (id === 3) {
        if (this.isInvteProp3 === 1) {
          this.ActReward({ code_type: 3 }).then(res => {
            this.awardPropId = '3'
            this.isShowAwardProp = true
            this.isInvteProp3 = 2
          })
        } else if (this.isInvteProp3 === 2) {
          this.showError('已經領取');
        } else {
          this.showError('邀請數量不足');
        }
      } else if (id === 4) {
        if (this.isInvteProp4 === 1) {
          this.ActReward({ code_type: 4 }).then(res => {
            this.awardPropId = '4'
            this.isShowAwardProp = true
            this.isInvteProp4 = 2
          })
        } else if (this.isInvteProp4 === 2) {
          this.showError('已經領取');
        } else {
          this.showError('邀請數量不足');
        }
      }
    },
    loadData () {
      this.ActAppointNum().then(res => {
        this.appointNum = res.count * 11
      })
    },
    loadInviteNum () {
      if (this.userInfo.token === '') {
        return false
      }
      this.ActInviteNum().then(res => {
        this.inviteNum = res.count
        if (this.inviteNum >= 1) {
          this.isInvteProp3 = 1
        }
        if (this.inviteNum >= 3) {
          this.isInvteProp3 = 1
          this.isInvteProp4 = 1
        }
        this.ActCodeList().then(res => {
          for (var i = 0; i < res.list.length; i++) {
            if (res.list[i].code_type === 3) {
              this.isInvteProp3 = 2
            }
            if (res.list[i].code_type === 4) {
              this.isInvteProp4 = 2
            }
          }
        })
      })
    },
    showAwardQuestion () {
      if (this.userInfo.token === '') {
        this.isShowActLogin = true
      } else {
        this.isShowAwardQuestion = true
      }
    },
    showAwardList () {
      if (this.userInfo.token === '' && !this.isReservation) {
        this.isShowAwardList = false
      } else {
        this.isShowAwardList = true
      }
    },
    activeClass (index) {
      this.show = true
      // this.currentActive = index
      document.querySelector('#' + index).scrollIntoView(
        { behavior: 'smooth' }
      )
    },
    homeClose () {
      this.show = true
    },
    linkToPage (name, url) {
      if (name === 'apple') {
        this.showSuccess('敬請期待')
      } else {
        this.ActAppStore()
        let id = name
        this.createSuperLabel(url, id)
      }
    },
    createSuperLabel (url, id) {
      let a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('target', '_blank');
      a.setAttribute('id', id);
      if (!document.getElementById(id)) {
        document.body.appendChild(a);
      }
      a.click();
    },
    tickVideo () {
      this.isShowVideoPlay = true
    },
    showClick () {
      this.disabled = 1
      console.log(this.disabled)
    },
    hiddenClick () {
      this.disabled = 0
    },
    copyUrl () {
      let that = this
      if (this.userInfo.token !== '') {
        let url = this.shareUrl + '?fromuid=' + this.userInfo.user_id
        this.$copyText(url).then(function (e) {
          that.showSuccess('複製成功');
        }, function (e) {
          that.showError('複製失敗');
        })
      } else {
        this.checkFbLogin()
        // this.isShowActLogin = true
      }
    },
    checkFbLogin () {
      if (!this.isReservation) {
        this.isShowActLogin = true
      } else {
        let that = this
        window.FB.getLoginStatus(function (response) {
          if (response.status === 'connected') {
            that.doLogin(response.authResponse);
          } else {
            that.tickLogin();
          }
        });
      }
    },
    doLogin (res) {
      let that = this
      that.ActLoginFb({ userId: res.userID, accessToken: res.accessToken }).then(() => {
        that.showSuccess('登陸成功')
      }).catch(() => {
        that.showError('登陸失敗')
      })
    },
    tickLogin () {
      let that = this
      window.FB.login(function (response) {
        if (response.status !== 'connected') {
          that.showError('登陸失敗')
          return;
        }
        let res = response.authResponse
        that.ActLoginFb({ userId: res.userID, accessToken: res.accessToken }).then(() => {
          that.showSuccess('登陸成功')
        }).catch(() => {
          that.showError('登陸失敗')
        })
      });
    }
  }
};
</script>
<style lang="scss">
  @import "@/assets/styles/reservation.scss";
  @import "@/assets/styles/reservation_pop.scss";
  @import "@/assets/mobile/mobile.scss";
  @import "@/assets/mobile/mobile_pop.scss";

</style>
