<template>
  <div class="dialog_wrap" :class="isMobile?'pop_mobile':'pop_pc'" @click="closeDialog">
  <div class="award-dialog" @click.stop="noTouch">
    <div class="dialog-close" @click="closeDialog"></div>
    <div class="award-content">
      <div class="award-title"><div class="award-rule-bg"></div></div>
      <div class="award-rule-content" v-html="ruleContent">
     
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AwardRule',
  props: {
    isShow: {
      type: Boolean,
      default: true
    }
  },
  computed: mapState({
    isMobile: (state) => state.act.isMobile,
  }),
  data () {
    return {
      list: [],
      ruleContent: '將專屬邀請碼發送給好友，好友透過該網址成功完成預約即為邀請成功，百分百獲得修仙大禮包喲！<br /> ①成功邀請一位好友參與預約即可獲得一份預約獎勵 <br /> ②成功邀請三位位好友參與預約即可獲得一份預約豪華獎勵 <br /> ③每人每個帳號僅限參與一次，邀請成功達到指定人數即可解鎖專屬修仙豪禮喲(๑•̀ㅂ•́)و✧'
    }
  },
  mounted () {
    document.getElementsByTagName('body')[0].classList.add('noscroll');
  },
  beforeDestroy () {
    document.getElementsByTagName('body')[0].classList.remove('noscroll');
  },
  methods: {
    noTouch () {

    },
    closeDialog () {
      this.$emit('onClose', true)
    },
    doCopy: function (val) {
      this.$copyText(val).then(function (e) {
        console.log('Copied=' + e.text)
      }, function (e) {
        console.log('Can not copy', e.text)
      })
    }
  }
}
</script>

<style lang="scss">

</style>
