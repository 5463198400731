<template>
<div class="dialog_wrap " :class="isMobile?'pop_mobile':'pop_pc'" @click="closeDialog">
  <div class="award-dialog success" @click.stop="noTouch">
    <div class="dialog-close" @click="closeDialog"></div>
    <div class="award-content">
      <div class="award-title">
        <div class="award-success-txt-bg">恭喜預約成功</div>

      </div>
      <div class="award-success-txt">預約成功後遊戲上市時將發送兌換碼簡訊</div>
      <div class="award-success-props" style="margin-top:30px">
          <div class="act-login-prop-item" v-for="(item,index) in props" v-bind:key="'prop-'+index">
              <div class="act-login-prop-icon" :class="item.icon"></div>
              <div class="act-login-prop-name">{{ item.name }}</div>
              <div class="act-login-prop-num">{{ item.num }}</div>
            </div>
        </div>
      <div class="award-success-content"><div class="award-success-msg">立即前往商店預約，領取追加特典！</div><div class="award-success-icon"></div></div>
      <div class="award-success-plat">
        <a class="award-success-google" href="javascript:void(0)" @click="linkToPage('google',links.google)"></a>
        <a class="award-success-apple"  href="javascript:void(0)" @click="linkToPage('apple',links.apple)"  v-if="links.apple"></a>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'AwardSuccess',
  props: {
    isShow: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters([
      'reservationMobile',
      'isMobile',
      'links'
    ])
  },
  data () {
    return {
      list: [],
      props: [
        { icon: 'prop-1', name: '暗香疏影', num: '*1' },
        { icon: 'prop-2', name: '綁玉', num: '*66' },
        { icon: 'prop-3', name: '仙王參', num: '*1' },
        { icon: 'prop-4', name: '金幣', num: '*3888' },
        { icon: 'prop-5', name: '尋仙令', num: '*5' }
      ],
    }
  },
  mounted () {
    document.getElementsByTagName('body')[0].classList.add('noscroll');
  },
  beforeDestroy () {
    document.getElementsByTagName('body')[0].classList.remove('noscroll');
  },
  methods: {
    ...mapActions(['ActAppStore']),
    noTouch () {

    },
    closeDialog () {
      if (this.$route.name === 'reservation') {
        this.$emit('onClose', true)
      } else {
        this.$router.push({ name: 'reservation', query: this.$route.query })
      }
    },
    linkToPage (name, url) {
      if (name === 'apple') {
        this.showSuccess('敬請期待')
      } else {
        this.ActAppStore()
        let id = name
        this.createSuperLabel(url, id)
      }
    },
    createSuperLabel (url, id) {
      let a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('target', '_blank');
      a.setAttribute('id', id);
      if (!document.getElementById(id)) {
        document.body.appendChild(a);
      }
      a.click();
    },
  }
}
</script>

<style lang="scss">

</style>
