<template>
  <div class="mobile-reservation" @scroll="centerScroll($event)">
    <div class="reservation_menu" v-if="disabled">
      <div class="kuang-close" @click="hiddenClick"></div>
      <ul>
        <li :class="{ active: 'center_top'=== currentActive }" @click="activeClass('center_top')">首頁</li>
        <li :class="{ active: 'center_before'=== currentActive }" @click="activeClass('center_before')">事前預約</li>
        <li :class="{ active: 'center_invite'=== currentActive }" @click="activeClass('center_invite')">好友邀請</li>
        <li :class="{ active: 'center_log'=== currentActive }" @click="activeClass('center_log')">修仙日誌</li>
        <li :class="{ active: 'center_game'=== currentActive }" @click="activeClass('center_game')">遊戲特色</li>
        <li @click="showAwardList">領獎紀錄</li>
      </ul>
    </div>
    <div class="reservation_main">
        <div class="reservation_main_top" id="center_top">
          <div class="reservation_main_top_head">
            <div class="logo">
              <img :src="staticUrl+'mobile/reservation/top-icon.png'" alt=""/>
            </div>
            <div class="title">
              <div class="title_text">仙宗大掌門</div>
              <div class="title_desc">修仙模擬經營</div>
            </div>
            <div class="top-btu">
              <a href="javascript:void(0)" @click="tickBook">
                <img :src="staticUrl+'mobile/reservation/top-btu.png'" alt=""/>
              </a>
            </div>
            <div class="top-kuang" @click="showClick"  v-if="!disabled">
              <img :src="staticUrl+'mobile/reservation/top-kuang.png'" alt=""/>
            </div>
          </div>

          <div class="reservation_main_top_mian">
            <div class="video_btn" @click="tickVideo">
              <img :src="staticUrl+'mobile/reservation/video_btn.png'" alt=""/>
            </div>
            <div class="text_num">
                已經<span class="">{{ appointNum }}</span>人預約
            </div>
            <div class="instant">
              <a class="link-btn google" href="javascript:void(0)" @click="linkToPage('google',links.google)">
                <img :src="staticUrl+'google_play_badge.png'" alt=""/>
              </a>
              <a class="link-btn apple" href="javascript:void(0)" @click="linkToPage('apple',links.apple)" v-if="links.apple">
                <img :src="staticUrl+'app_store.png'" alt=""/>
              </a>
            </div>
            <div class="top-btu">
              <!-- <a class="link-btn book" href="javascript:void(0)" @click="tickBook">
                <img :src="staticUrl+'mobile/reservation/top-btu.png'" alt="" class="iphone ani"/>
              </a> -->
              <a class="link-btn book" href="javascript:void(0)" @click="activeClass('login_iphone')">
                <img :src="staticUrl+'mobile/reservation/top-btu.png'" alt="" class="iphone ani"/>
              </a>
            </div>
          </div>
        </div>

        <div class="reservation_main_instant" id="center_before">
          <div class="instant_title_text">
            <img :src="staticUrl+'mobile/reservation/text1.png'"/>
          </div>
          <div class="instant_title_bt">
            <img :src="staticUrl+'mobile/reservation/bt1.png'"/>
          </div>
          <ul class="instant_center">
            <li>
              <img :src="staticUrl+'icon/icon_1.png'" alt="" class="instant_img"/>
              <p class="instant_desc">暗香疏影</p>
              <p class="instant_title">*1</p>
            </li>
            <li>
              <img :src="staticUrl+'icon/icon_2.png'" alt="" class="instant_img"/>
              <p class="instant_desc">綁玉</p>
              <p class="instant_title">*66</p>
            </li>
            <li>
              <img :src="staticUrl+'icon/icon_3.png'" alt="" class="instant_img"/>
              <p class="instant_desc">仙王參</p>
              <p class="instant_title">*1</p>
            </li>
            <li>
              <img :src="staticUrl+'icon/icon_4.png'" alt="" class="instant_img"/>
              <p class="instant_desc">金幣</p>
              <p class="instant_title">*3888</p>
            </li>
            <li>
              <img :src="staticUrl+'icon/icon_5.png'" alt="" class="instant_img"/>
              <p class="instant_desc">尋仙令</p>
              <p class="instant_title">*5</p>
            </li>
          </ul>
          <div class="instant_center_login" id="login_iphone">
            <!-- <div class="instant_center_login_iphone">+886(台灣)<input type="text" placeholder="請輸入手機號碼" class="code-input"></div> -->
            <div class="mobile-form">
              <el-select class="area-select" v-model="mobileAreaCode" @change="changeAreaCode" size="medium" placeholder="請選擇" v-if="!isMobile">
                <el-option
                  v-for="item in mobilePres"
                  :key="'pres-'+item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <select v-model="mobileAreaCode"  @change="changeAreaCode" v-else>
                <option v-for="item in mobilePres"
                  :key="'pres-'+item.value"
                  :value="item.value">{{ item.label }}</option>
              </select>
              <div class="mobile-input-wrap"><input class="mobile-input" type="tel"  :placeholder="mobileHolder" v-model="mobileNum" /></div>
            </div>
            <div class="login-checkbox"><input type="checkbox" value="">我同意個人資料的收集及接收獎勵簡訊,<a href="/privacy/doc" target="_blank">隱私協議</a></div>
            <img :src="staticUrl+'mobile/reservation/bt1_yuyue_btn.png'" alt="" class="yuyue ani"/>
          </div>
          <div class="instant_center_step">
            <div class="instant_center_step_left">
              <img :src="staticUrl+'mobile/reservation/step2-icon.png'" />
              <p>按讚抽取</p>
            </div>
            <div class="instant_center_step_right">
              <img :src="staticUrl+'mobile/reservation/step2.png'" />
              <a :href="links.facebook" target="_blank"><img :src="staticUrl+'mobile/reservation/step2_fb_but.png'" /></a>
            </div>
          </div>
          <div class="instant_center_step">
            <div class="instant_center_step_left">
              <img :src="staticUrl+'mobile/reservation/step3-icon.png'" />
              <p>特典好禮</p>
            </div>
            <div class="instant_center_step_right">
              <img :src="staticUrl+'mobile/reservation/text3.png'" />
              <a href="javascript:void(0)" @click="linkToPage('google',links.google)">
                <img :src="staticUrl+'mobile/reservation/bt3_google_but.png'" />
              </a>
              <a href="javascript:void(0)" @click="linkToPage('apple',links.apple)" v-if="links.apple!==''">
                <img :src="staticUrl+'mobile/reservation/bt3_app_but.png'" />
              </a>
            </div>
          </div>
        </div>

        <div class="reservation_main_invite" id="center_invite">
          <div class="invite_num">
            <ul class="num_center_num">
                <li>3萬</li>
                <li>5萬</li>
                <li>10萬</li>
                <li>15萬</li>
                <li>20萬</li>
            </ul>
            <ul class="num_center_reward">
                <li>
                    <img :src="staticUrl+'mobile/reservation/bt2-reward-img1.png'" alt="" class="goods_img"/>
                    <img :src="staticUrl+'mobile/reservation/select.png'" alt="" class="goods_select" v-if="selectIndex===3"/>
                    <div class="hover">
                        <div class="hover_content">
                          <p class="hover_title">獎勵內容</p>
                          <p class="hover_desc">金幣*2888<br/>儲物戒*2</p>
                        </div>
                    </div>
                </li>
                <li>
                    <img :src="staticUrl+'mobile/reservation/bt2-reward-img2.png'" alt="" class="goods_img"/>
                    <img :src="staticUrl+'mobile/reservation/select.png'" alt="" class="goods_select" v-if="selectIndex===5"/>
                    <div class="hover">
                      <div class="hover_content">
                        <p class="hover_title">獎勵內容</p>
                        <p class="hover_desc">進階丹*288<br/>珍禽腿*1</p>
                      </div>
                    </div>
                </li>
                <li>
                    <img :src="staticUrl+'mobile/reservation/bt2-reward-img3.png'" alt="" class="goods_img"/>
                    <img :src="staticUrl+'mobile/reservation/select.png'" alt="" class="goods_select" v-if="selectIndex===10"/>
                    <div class="hover">
                      <div class="hover_content">
                        <p class="hover_title">獎勵內容</p>
                        <p class="hover_desc">綁玉*88<br/>塵務錄*3</p>
                      </div>
                    </div>
                </li>
                <li>
                    <img :src="staticUrl+'mobile/reservation/bt2-reward-img4.png'" alt="" class="goods_img"/>
                    <img :src="staticUrl+'mobile/reservation/select.png'" alt="" class="goods_select" v-if="selectIndex===15"/>
                    <div class="hover">
                      <div class="hover_content">
                        <p class="hover_title">獎勵內容</p>
                        <p class="hover_desc">招募令*10<br/>淬鐵石*2</p>
                      </div>
                    </div>
                </li>
                <li>
                    <img :src="staticUrl+'mobile/reservation/bt2-reward-img5.png'" alt="" class="goods_img"/>
                    <img :src="staticUrl+'mobile/reservation/select.png'" alt="" class="goods_select" v-if="selectIndex===20"/>
                    <div class="hover">
                      <div class="hover_content">
                        <p class="hover_title">獎勵內容</p>
                        <p class="hover_desc">尋仙令*10<br/>點金石*1</p>
                      </div>
                    </div>
                </li>
            </ul>
          </div>
          <div class="reservation_main_img"><img :src="staticUrl+'mobile/reservation/bt3.png'" alt=""/></div>
          <div class="invite_center">
            <div class="invite_center_item">
              <div class="title">邀請<span>1</span>人獎勵</div>
              <div class="desc">
                <div>
                  <img :src="staticUrl+'icon/bt3-icon1.png'" alt=""/><br>
                  <p>金幣*3888</p>
                </div>
                <div>
                  <img :src="staticUrl+'icon/bt3-icon2.png'" alt=""/><br>
                  <p>尋仙令*1</p>
                </div>
                <div>
                  <img :src="staticUrl+'icon/bt3-icon3.png'" alt=""/><br>
                  <p>招募令*5</p>
                </div>
              </div>
              <div class="btn">
                <button type="button" @click="getAwardProp(3)" class="yilingqu" v-if="isInvteProp3===2">已領取</button>
                <button type="button" @click="getAwardProp(3)" class="nolingqu" v-else-if="isInvteProp3===1">領取獎勵</button>
                <button type="button" @click="getAwardProp(3)" class="nojiesuo" v-else>未成功邀請</button>
              </div>
              <div class="btn2">
                <button type="button" @click="copyUrl()" class="copy_but" v-if="isReservation && this.userInfo.token">複製連結</button>
                <button type="button" @click="checkFbLogin()" class="fb_but" v-else-if="isReservation && this.userInfo.token===''">FB登入</button>
                <button type="button" @click="tickBook" class="yuyue_but" v-else>立即預約</button>
              </div>
            </div>
            <div class="invite_center_item invite_center_item2">
              <div class="title">邀請<span>3</span>人獎勵</div>
              <div class="desc">
                <div>
                  <img :src="staticUrl+'icon/bt3-icon4.png'" alt=""/><br>
                  <p>綁玉*68</p>
                </div>
                <div>
                  <img :src="staticUrl+'icon/bt3-icon2.png'" alt=""/><br>
                  <p>尋仙令*5</p>
                </div>
                <div>
                  <img :src="staticUrl+'icon/bt3-icon5.png'" alt=""/><br>
                  <p>進階丹*288</p>
                </div>
              </div>
              <div class="btn">
                <button type="button" @click="getAwardProp(4)" class="yilingqu" v-if="isInvteProp4===2">已領取</button>
                <button type="button" @click="getAwardProp(4)" class="nolingqu" v-else-if="isInvteProp4===1">領取獎勵</button>
                <button type="button" @click="getAwardProp(4)" class="nojiesuo" v-else>未成功邀請</button>
              </div>
              <div class="btn2">
                <button type="button" @click="isShowAwardRule=true" class="rule_but">活動規則</button>
              </div>
            </div>
          </div>
        </div>

        <div class="reservation_main_game" id="center_log">
          <div class="reservation_main_img">
            <img :src="staticUrl+'mobile/reservation/bt4.png'" alt=""/>
          </div>
          <div class="log_center">
            <a  href="javascript:void(0)" @click="showAwardQuestion" v-if="isReservation && this.userInfo.token">
              <img  class="question-btn ani" :src="staticUrl+'reservations/bt4_but.png'" alt=""/>
            </a>
            <a  href="javascript:void(0)" @click="checkFbLogin" v-else-if="isReservation && this.userInfo.token===''">
              <img  class="question-btn ani" :src="staticUrl+'reservations/bt4_but_fb.png'" alt=""/>
            </a>
            <a  href="javascript:void(0)" @click="tickBook" v-else>
              <img  class="question-btn ani" :src="staticUrl+'reservations/bt4_but_yuyue.png'" alt=""/>
            </a>
          </div>
          <div class="log_center_tip">活動規則：參與修仙日誌小測試，測一測你的修仙結局吧~參與即可獲得一份修仙獎勵喲~</div>

          <div class="reservation_main_img" id="center_game">
            <img :src="staticUrl+'mobile/reservation/bt5.png'" alt=""/>
          </div>
          <div class="game_center">
            <div class="game_center_prev"><img :src="staticUrl+'mobile/reservation/prev_but.png'"></div>
            <div class="game_center_swiper_swiping">
              <swiper :options="swiperOptions">
                <swiper-slide v-for="(item,index) in swipingData2" :key="index">
                  <img :src="item.image">
                </swiper-slide>
              </swiper>
            </div>
            <div class="game_center_next"><img :src="staticUrl+'mobile/reservation/next_but.png'"></div>
          </div>
          <div class="game_pagination" slot="pagination"></div>

          <div class="game_link">
            <h4>注意事項</h4>
            <p>1.事前預約活動時間為 即日起至遊戲開服前一天的23:59。</p>
            <p>2.每個Facebook帳號、手機號碼，僅限參加活動一次，每項獎勵僅限領取一次。如因個人資料輸入錯誤導致獎勵無法正確發送，恕不補發。</p>
            <p>3.手機預約獎勵，將在公測後透過手機簡訊發放，請密切留意通知簡訊。獎勵序號僅限每個角色兌換1次，一經兌換，該序號即視爲失效。兌換期限至2023/1/31 23:59。</p>
            <p>4.預約人數達指定目標獎勵，將在公測後7天內可通過遊戲内領取。(範例：預約人數如達20萬，則全服可領取到3、5、10、15、20萬人的獎勵)</p>
            <p>5.實體獎勵將於活動結束後統一寄送，煩請密切留意領獎通知簡訊。</p>
            <p>6.主辦單位將以手機簡訊主動通知實體獎得獎者，得獎者需於收到通知5天內回覆領獎憑證，方可獲得贈品，逾期視同放棄領獎資格 。</p>
            <p>7.主辦單位將於收回領據後寄出贈品，得獎者若於寄出後20日內尚未收到獎品，煩請主動通知主辦單位，逾期視同放棄領獎資格。實物獎項以實物為準，限得獎者本人領取，且不得要求折抵現金、轉讓他人領獎或兌換其他獎項或優惠。</p>
            <p>8.依稅法規定，得獎價值價值超過新台幣20,000元以上，得獎者須自行負擔贈品稅，得獎者之獎品價值超過新台幣1,000元（含）以上者，須提供身份證正、反面影本，以利廠商申報中獎扣繳憑單。</p>
            <p>9.主辦單位保留變更或終止本活動之權利，變更內容將於活動網站調整，恕不另行通知。</p>
          </div>
        </div>

        <div class="reservation_foot">
          <a :href="links.discord" target="_blank"><img :src="staticUrl+'foot_discord.png'" class="reservation_foot_small"/></a>
          <a :href="links.facebook" target="_blank"><img :src="staticUrl+'mobile/reservation/foot_fb.png'" class="reservation_foot_large"/></a>
        </div>
    </div>

    <ActLogin  @onClose='isShowActLogin=false' v-if="isShowActLogin"/>
    <AwardList  @onClose='isShowAwardList=false' v-if="isShowAwardList" />
    <AwardRule  @onClose='isShowAwardRule=false' v-if="isShowAwardRule" />
    <AwardSuccess  @onClose='isShowAwardSuccess=false' v-if="isShowAwardSuccess" />
    <AwardProp  :propId="awardPropId" @onClose='isShowAwardProp=false' v-if="isShowAwardProp" />
    <AwardQuestion  @onClose='isShowAwardQuestion=false' v-if="isShowAwardQuestion" />
    <VideoPlay :src="videoUrl" :controls="isControls" @onClose='isShowVideoPlay=false' v-if="isShowVideoPlay" />
  </div>
</template>

<script>
import VideoPlay from '@/components/reservation/VideoPlay'
import ActLogin from '@/components/reservation/ActLogin'
import AwardList from '@/components/reservation/AwardList'
import AwardRule from '@/components/reservation/AwardRule'
import AwardSuccess from '@/components/reservation/AwardSuccess'
import AwardProp from '@/components/reservation/AwardProp'
import AwardQuestion from '@/components/reservation/AwardQuestion'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'reservationMobile',
  components: {
    ActLogin,
    AwardList,
    AwardRule,
    AwardSuccess,
    AwardProp,
    AwardQuestion,
    VideoPlay
  },
  computed: {
    ...mapGetters([
      'userInfo',
      'links',
      'staticUrl',
      'isAppStore',
      'videoUrl',
      'isMobile',
      'isReservation',
    ])
  },
  data () {
    return {
      isCheck: false,
      mobilePres: [{
        value: '+886',
        label: '+886(台灣)'
      }, {
        value: '+852',
        label: '+852(香港)'
      }, {
        value: '+853',
        label: '+853(澳門)'
      }],
      mobileAreaCode: '+886',
      mobileHolder: '9xxxxxxxx(共9位)',
      mobileNum: '',
      isControls: true,
      isShowVideoPlay: false,
      disabled: 0,
      step2Click: false,
      selectIndex: 3,
      isShowActLogin: false,
      isShowAwardList: false,
      isShowAwardRule: false,
      isShowAwardSuccess: false,
      isShowAwardProp: false,
      isShowAwardQuestion: false,
      awardPropId: '2',
      inviteNum: 0,
      appointNum: 0,
      isInvteProp3: 0,
      isInvteProp4: 0,
      shareUrl: 'https://www.kaguyajoy.com.tw/reservation',
      currentActive: 'center_top',
      show: false,
      swipingData2: [
        // eslint-disable-next-line no-undef
        { name: '1', image: staticUrl + 'mobile/reservation/img-1.png' },
        // eslint-disable-next-line no-undef
        { name: '2', image: staticUrl + 'mobile/reservation/img-2.png' },
        // eslint-disable-next-line no-undef
        { name: '3', image: staticUrl + 'mobile/reservation/img-3.png' },
        // eslint-disable-next-line no-undef
        { name: '4', image: staticUrl + 'mobile/reservation/img-4.png' },
        // eslint-disable-next-line no-undef
        { name: '5', image: staticUrl + 'mobile/reservation/img-5.png' },
        // eslint-disable-next-line no-undef
        { name: '6', image: staticUrl + 'mobile/reservation/img-6.png' },
        // eslint-disable-next-line no-undef
      ],
      swiperOptions: {
        centeredSlides: true,
        spaceBetween: 30,
        autoplay: {
          delay: 5000,
          disableOnInteraction: true
        },
        pagination: {
          el: '.game_pagination',
          type: 'bullets',
          clickable: false,
          progressbarOpposite: true,
        },
        loop: true,
        navigation: {
          nextEl: '.game_center_next',
          prevEl: '.game_center_prev'
        }
      }
    }
  },
  mounted () {
    document.addEventListener('scroll', this.centerScroll, true)
    this.saveQuery()
    if (this.$route.name === 'reservationSuccess' || this.$route.name === 'reservationOk') {
      this.isShowAwardSuccess = true
    } else {
      if (this.userInfo.token === '') {
        this.isShowActLogin = true
      } else {
        this.showPropbyUrl()
        this.loadInviteNum()
      }
    }
    this.loadData()
  },
  created () {
    console.log(this.userInfo)
  },
  methods: {
    ...mapActions(['ActAppointNum', 'ActInviteNum', 'ActCodeList', 'ActAppStore', 'ActReward', 'ActSetInvite']),
    onQuestionProp () {
      this.isShowAwardQuestion = false
      this.awardPropId = '5'
      this.isShowAwardProp = true
    },
    centerScroll () {
      var scrollVals = [{ top: 0, id: 'center_top' }, { top: 100, id: 'center_before' }, { top: 200, id: 'center_invite' }, { top: 300, id: 'center_log' }, { top: 400, id: 'center_game' }];
      scrollVals[0].top = document.querySelector('#center_top').getBoundingClientRect().top
      scrollVals[1].top = document.querySelector('#center_before').getBoundingClientRect().top
      scrollVals[2].top = document.querySelector('#center_invite').getBoundingClientRect().top
      scrollVals[3].top = document.querySelector('#center_log').getBoundingClientRect().top
      scrollVals[4].top = document.querySelector('#center_game').getBoundingClientRect().top
      let minTopArr = this.topSort(scrollVals, 'top')
      if (this.currentActive !== minTopArr[0].id) {
        this.currentActive = minTopArr[0].id
      }
    },
    topSort (objArr, key) {
      let result = objArr.slice(0);
      return result.sort((a, b) => Math.abs(a[key]) - Math.abs(b[key]));
    },
    changeAreaCode () {
      switch (this.mobileAreaCode) {
        case '+886':
          this.mobileHolder = '9xxxxxxxx(共9位)'
          break;
        case '+852':
          this.mobileHolder = 'xxxxxxxx(共8位)'
          break;
        case '+853':
          this.mobileHolder = '6xxxxxxx(共8位)'
          break;
      }
      this.mobileNum = ''
    },
    checkboxChange (e) {
      this.isCheck = e.target.checked
    },
    actAppoint () {
      if (this.mobileNum.length < 8 || this.mobileNum.length > 9) {
        this.showError('請輸入正確手機號')
        return;
      }
      if (!this.isCheck) {
        this.showError('請確認隱私協議選項內容')
        return;
      }
      let mobile = this.mobileAreaCode + this.mobileNum;
      this.ActAppoint({ mobile: mobile }).then(() => {
        this.ActReservation()
        this.$router.push({ name: 'reservationSuccess' })
      }).catch((err) => {
        console.log(err)
        this.showSuccess('預約失敗')
      })
    },
    saveQuery () {
      if (typeof (this.$route.query) !== 'undefined') {
        if (typeof (this.$route.query['fromuid']) !== 'undefined') {
          this.ActSetInvite({ invite_uid: parseInt(this.$route.query['fromuid']) })
        }
      }
    },
    tickVideo () {
      if (this.userInfo.token === '') {
        this.isShowVideoPlay = true
      } else {
        this.isShowVideoPlay = true
      }
    },
    tickBook () {
      if (this.userInfo.token === '') {
        this.isShowActLogin = true
      } else {
        this.isShowAwardSuccess = true
      }
    },
    showPropbyUrl () {
      if (typeof (this.$route.query) !== 'undefined') {
        if (typeof (this.$route.query['prop']) !== 'undefined') {
          this.awardPropId = this.$route.query['prop'] + ''
          this.isShowAwardProp = true
        }
      }
    },
    getAwardProp (id) {
      if (id === 2) {
        if (this.isAppStore === 1) {
          this.ActReward({ code_type: 2 }).then(res => {
            this.awardPropId = '2'
            this.isShowAwardProp = true
          })
        } else {
          this.showError('請到商店預約');
        }
      } else if (id === 3) {
        if (this.isInvteProp3 === 1) {
          this.ActReward({ code_type: 3 }).then(res => {
            this.awardPropId = '3'
            this.isShowAwardProp = true
            this.isInvteProp3 = 2
          })
        } else if (this.isInvteProp3 === 2) {
          this.showError('已經領取');
        } else {
          this.showError('邀請數量不足');
        }
      } else if (id === 4) {
        if (this.isInvteProp4 === 1) {
          this.ActReward({ code_type: 4 }).then(res => {
            this.awardPropId = '4'
            this.isShowAwardProp = true
            this.isInvteProp4 = 2
          })
        } else if (this.isInvteProp4 === 2) {
          this.showError('已經領取');
        } else {
          this.showError('邀請數量不足');
        }
      }
    },
    loadData () {
      this.ActAppointNum().then(res => {
        this.appointNum = res.count
      })
    },
    loadInviteNum () {
      this.ActInviteNum().then(res => {
        this.inviteNum = res.count
        if (this.inviteNum >= 1) {
          this.isInvteProp3 = 1
        }
        if (this.inviteNum >= 3) {
          this.isInvteProp3 = 1
          this.isInvteProp4 = 1
        }
        this.ActCodeList().then(res => {
          for (var i = 0; i < res.list.length; i++) {
            if (res.list[i].code_type === 3) {
              this.isInvteProp3 = 2
            }
            if (res.list[i].code_type === 4) {
              this.isInvteProp4 = 2
            }
          }
        })
      })
    },
    showAwardQuestion () {
      if (this.userInfo.token === '') {
        this.isShowActLogin = true
      } else {
        this.isShowAwardQuestion = true
      }
    },
    showAwardList () {
      if (this.userInfo.token === '' && !this.isReservation) {
        this.isShowAwardList = false
      } else {
        this.isShowAwardList = true
      }
    },
    activeClass (index) {
      this.show = true
      this.currentActive = index
      document.querySelector('#' + index).scrollIntoView(
        { behavior: 'smooth' }
      )
    },
    homeClose () {
      this.show = true
    },
    linkToPage (name, url) {
      if (name === 'apple') {
        this.showSuccess('敬請期待')
      } else {
        this.ActAppStore()
        let id = name
        this.createSuperLabel(url, id)
      }
    },
    createSuperLabel (url, id) {
      let a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('target', '_blank');
      a.setAttribute('id', id);
      if (!document.getElementById(id)) {
        document.body.appendChild(a);
      }
      a.click();
    },
    copyUrl () {
      let that = this
      if (this.userInfo.token !== '') {
        let url = this.shareUrl + '?fromuid=' + this.userInfo.user_id
        this.$copyText(url).then(function (e) {
          that.showSuccess('複製成功');
        }, function (e) {
          that.showError('複製失敗');
        })
      } else {
        this.isShowActLogin = true
      }
    },
    checkFbLogin () {
      if (!this.isReservation) {
        this.isShowActLogin = true
      } else {
        let that = this
        window.FB.getLoginStatus(function (response) {
          if (response.status === 'connected') {
            that.doLogin(response.authResponse);
          } else {
            that.tickLogin();
          }
        });
      }
    },
    showClick () {
      this.disabled = 1
      console.log(this.disabled)
    },
    hiddenClick () {
      this.disabled = 0
    },
  }
};
</script>
<style lang="scss">

  @import "@/assets/mobile/mobile.scss";
  @import "@/assets/mobile/mobile_pop.scss";

</style>
