import Vue from 'vue'
import VueRouter from 'vue-router'
import BaseLayout from '@/views/BaseLayout';

import ReservationPc from '@/views/ReservationPcView';
import ReservationMobile from '@/views/mobile/ReservationMobileView';
import AccountDoc from '@/views/mobile/AccountDoc';
import PrivacyDoc from '@/views/mobile/PrivacyDoc';

// hack router push callback
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'index',
    component: BaseLayout,
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import('@/views/IndexView')
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('@/views/AboutView')
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('@/views/ContactView')
      },
      {
        path: '/service',
        name: 'service',
        component: () => import('@/views/ServiceView')
      },
      {
        path: '/privacy',
        name: 'privacy',
        component: () => import('@/views/PrivacyView')
      },
      {
        path: '/game',
        name: 'game',
        component: () => import('@/views/GameView')
      },
    ]
  },
  {
    path: '/reservation',
    name: 'reservation',
    component: ReservationPc
  },
  {
    path: '/reservation/success',
    name: 'reservationSuccess',
    component: ReservationPc
  },
  {
    path: '/reservation/ok',
    name: 'reservationOk',
    component: ReservationPc
  },
  {
    path: '/reservation/mobile',
    name: 'reservationMobile',
    component: ReservationMobile
  },
  {
    path: '/account/doc',
    name: 'accountDoc',
    component: AccountDoc
  },
  {
    path: '/privacy/doc',
    name: 'privacyDoc',
    component: PrivacyDoc
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router;
