<template>
    <div class="mobile-account-doc" >
        <div class="account-title" >{{ title }}</div>
        <div class="account-content">
              <p style="font-weight: bold;">※ 隱私權保護政策的適用範圍 ※</p>
              <p>1. 隱私權保護政策內容，包括本公司如何處理在用戶使用本公司服務時收集到的身份識別資料，也包括本公司如何處理在商業伙伴與本公司合作時分享的身份識別資料。</p>
              <p>2. 隱私權保護政策不適用於本公司以外的公司，也不適用於非本公司所僱用或管理的人員。</p>
              <p style="font-weight: bold;">※ 資料收集及使用方式 ※</p>
              <p>1. 本公司在您註冊本公司帳號、使用本公司的產品或服務、瀏覽本公司網頁、參加宣傳活動或贈獎遊戲時，本公司會收集您的個人識別資料。本公司也可以從商業夥伴處取得個人資料。</p>
              <p>2. 當您在本公司註冊時，我們會問及您的姓名、電子郵件地址、性別、生日、居住地址等資料。您在本公司註冊成功，並登入使用我們的服務後，我們就會認識您。</p>
              <p>3. 本公司會使用資料作以下用途：改進並依建議或需求為您提供的廣告及網頁服務內容；完成您對某項產品或服務的建議或需求；以及通知您關於特別活動或新產品、新服務之內容。</p>
              <p style="font-weight: bold;">※ 資料分享及公開方式 ※</p>
              <p>1. 本公司不會向任何人出售或出借您的個人識別資料。</p>
              <p>2. 在以下的情況下，本公司會向其他人士或公司提供您的個人識別資料：</p>
              <p>a. 與其他人士或公司共用資料前取得您的同意。</p>
              <p>b. 需要與其他人士或公司共用您的資料，才能夠提供您要求的產品或服務。</p>
              <p>c. 向代表本公司提供服務或產品的公司提供資料，以便向您提供產品或服務。（若我們沒有事先通知您，這些公司均無權使用我們提供的個人資料，作提供產品或服務以外的其他用途）</p>
              <p>d. 應遵守法令或政府機關的要求；或我們發覺您在本公司產品上的行為違反本公司會員公約中的條款或產品、服務的特定使用指南時。</p>
              <p>3. 您可以從本公司所提供之互動設定選項，依需求自行選擇並設定是否公開您的姓名、電子郵件地址、性別、生日、居住地址等資料。</p>
              <p style="font-weight: bold;">※ 修改會員個人帳號資料的權利 ※</p>
              <p>1. 本公司賦予您修改個人在本公司中會員資料的權利，包括接受本公司通知您特別活動或新產品的決定權。</p>
              <p>2. 本網會依照隱私權政策保護所有會員的隱私，不管是申請帳號、個人資料或所儲存的網站、遊戲資料，除了可能涉及違法、侵權、或違反使用條款、或經本人同意以外，本公司不會任意監視、增刪、修改或關閉，或將個人資料內容交予第三者，包括讚助之廣告廠商。</p>
              <p style="font-weight: bold;">※ 保全 ※</p>
              <p>1. 為保障您的隱私及安全，您在本公司的帳號資料會善盡保護。</p>
              <p>2. 當您進入會員資料編輯及部分擁有個人資料及隱私頁面時，本公司會要求您輸入帳號密碼識別資料確認身分。</p>
              <p style="font-weight: bold;">※ 隱私權保護政策修訂 ※</p>
              <p>本公司可以不時修訂本政策。當我們在使用個人資料的規定上作出大修改時，我們會在網頁上張貼告示，並通知您相關修改事項。</p>
        </div>
    </div>
</template>

<script>

export default {
  name: 'privacyDoc',
  data () {
    return {
      title: '輝夜遊戲隱私權保護政策',
      content: ''
    }
  },
  mounted () {

  },
  created () {
  },
  methods: {
  }
}
</script>

<style lang="scss">
  @import "@/assets/mobile/account_doc.scss";
</style>
