import axios from 'axios';
// import qs from 'qs'
import Vue from 'vue';
import storage from '@/utils/localStorage'
import utils from '@/utils/utils'

let loginFlag = true;
let deviceId = storage.get('device_id')
if (deviceId == null) {
  deviceId = utils.getDeviceId()
  storage.set('device_id', deviceId)
}

// 創建axios实例
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API,
  baseURL: window.apiUrl,
  timeout: 5000,
});

service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
service.interceptors.request.use((config) => {
  if (config.method === 'post') {
    if (typeof (config.data['act']) !== 'undefined') {
      if (process.env.NODE_ENV === 'development') {
        // config.baseURL = 'http://10.17.14.12:8010'
        config.baseURL = '/act'
      } else {
        config.baseURL = 'https://zhuzisdk.bamboo-game.com'
      }
      config.data['app_id'] = 1011
      config.data['device_id'] = deviceId
      config.data['plat'] = 'zhuzi'
      config.data['channel'] = 'book'
    } else {
      config.data = {
        ...config.data,
        // debug_agent_id: 90,
        format: 'json',
      };
    }
  } else if (config.method === 'get') {
    config.params = {
      // debug_agent_id: 90,
      ...config.params,
      format: 'json',
    };
  }
  config.withCredentials = true;

  return config;
}, (error) => Promise.reject(error));
service.interceptors.response.use((response) => {
  if (loginFlag) {
    if (response.data.code === 1002) {
      loginFlag = false;
      Vue.prototype.showNotify('失败', '登入已过期，请重新登入！', 'error', () => {
        location.href = process.env.VUE_APP_BASE_API;
      });
    }
  }
  return response;
}, (error) => Promise.resolve(error.response));

export default service;
