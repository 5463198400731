import service from '@/utils/services'
import storage from '@/utils/localStorage'
import utils from '@/utils/utils'

const isMobile = utils.isMobile()

const state = {
  user: {
    user_id: 0,
    token: ''
  },
  isMobile: isMobile,
  invite_uid: 0,
  isAppStore: 0,
  links: {
    google: 'https://play.google.com/store/apps/details?id=com.bamboogame.xzthm',
    facebook: 'https://www.facebook.com/xzthm/',
    discord: 'https://discord.gg/ugNgkTP8fz',
    apple: 'https://play.google.com/store/apps/details?id=com.bamboogame.xzthm'
  },
  videoUrl: 'https://img.kaguyajoy.com.tw/web/video/recomm.mov',
  isReservation: 0,
  reservation_mobile: ''
}

const getters = {
  userInfo: (state) => {
    if (state.user.token === '') {
      let storageInfo = storage.get('obj_user')
      if (storageInfo !== null) {
        Object.assign(state.user, storageInfo)
      }
    }
    return state.user
  },
  links: state => state.links,
  isMobile: state => state.isMobile,
  isAppStore: (state) => {
    if (state.isAppStore === 0) {
      let storageInfo = storage.get('appstore')
      if (storageInfo !== null) {
        state.isAppStore = 1
      }
    }
    return state.isAppStore
  },
  inviteUid: (state) => {
    if (state.invite_uid === 0) {
      let storageInfo = storage.get('invite_uid')
      if (storageInfo !== null) {
        state.invite_uid = parseInt(storageInfo)
      }
    }
    return state.invite_uid
  },
  videoUrl: state => state.videoUrl,
  isReservation: (state) => {
    if (state.isReservation === 0) {
      let isReservation = storage.get('isReservation')
      if (isReservation !== null) {
        state.isReservation = 1
      }
    }
    return state.isReservation
  },
  reservationMobile: (state) => {
    if (state.reservation_mobile === '') {
      let reservationMobile = storage.get('reservation_mobile')
      if (reservationMobile !== null) {
        state.reservation_mobile = reservationMobile
      }
    }
    return state.reservation_mobile
  }
}

const mutations = {
  updateUser (state, params) {
    Object.assign(state.user, params)
    storage.set('obj_user', state.user)
  },
  updateAppStore (state, v) {
    state.isAppStore = v
    storage.set('appstore', v)
  },
  updateInviteUid (state, v) {
    state.invite_uid = v
    storage.set('invite_uid', v)
  },
  updateReservation (state, v) {
    state.isReservation = 1
    state.reservation_mobile = v
    storage.set('isReservation', 1)
    storage.set('reservation_mobile', v)
  },
}

const actions = {
  ActSetInvite ({ commit }, params) {
    commit('updateInviteUid', params.invite_uid)
  },
  ActAppStore ({ commit }) {
    commit('updateAppStore', 1)
  },
  ActReservation ({ commit }, params) {
    commit('updateReservation', params.mobile)
  },
  ActLoginFb ({ commit }, params) {
    return new Promise((resolve, reject) => {
      service.post('/login/meta', { act: true, userId: params.userId, accessToken: params.accessToken }).then((res) => {
        console.log(res)
        if (res.data.code === 0) {
          commit('updateUser', { user_id: res.data.data.user_id, token: res.data.data.token })
          resolve()
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  ActLogin ({ commit }, params) {
    return new Promise((resolve, reject) => {
      service.post('/login/mobile', { act: true, mobile: params.mobile, code: params.code }).then((res) => {
        if (res.data.code === 0) {
          commit('updateUser', { user_id: res.data.data.user_id, token: res.data.data.token })
          resolve()
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  ActCode ({ commit }, params) {
    return new Promise((resolve, reject) => {
      service.post('/code/appoint', { act: true, mobile: params.mobile }).then((res) => {
        if (res.data.code === 0) {
          resolve(res.data.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  ActAppoint ({ commit, state, getters }, params) {
    return new Promise((resolve, reject) => {
      service.post('/xz/appoint', { act: true, mobile: params.mobile, utm_source: params.utm_source, os: params.os, invite_user_id: getters.inviteUid }).then((res) => {
        if (res.data.code === 0) {
          resolve(res.data.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  ActAppointOld ({ commit, state, getters }) {
    return new Promise((resolve, reject) => {
      service.post('/xz/appoint', { act: true, user_id: state.user.user_id, token: state.user.token, invite_user_id: getters.inviteUid }).then((res) => {
        if (res.data.code === 0) {
          resolve(res.data.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  ActReward ({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      service.post('/xz/award', { act: true, token: state.user.token, user_id: state.user.user_id, code_type: params.code_type }).then((res) => {
        if (res.data.code === 0) {
          resolve(res.data.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  ActAppointNum ({ commit, state }) {
    return new Promise((resolve, reject) => {
      service.post('/xz/appointNum', { act: true, token: state.user.token, user_id: state.user.user_id }).then((res) => {
        if (res.data.code === 0) {
          resolve(res.data.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  ActInviteNum ({ commit, state }) {
    return new Promise((resolve, reject) => {
      service.post('/xz/inviteNum', { act: true, token: state.user.token, user_id: state.user.user_id }).then((res) => {
        if (res.data.code === 0) {
          resolve(res.data.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
  ActCodeList ({ commit, state }) {
    return new Promise((resolve, reject) => {
      service.post('/xz/codeList', { act: true, token: state.user.token, user_id: state.user.user_id }).then((res) => {
        if (res.data.code === 0) {
          resolve(res.data.data)
        } else {
          reject(res.data.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}
