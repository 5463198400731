<template>
  <div class="footbar">
    <div class="left">
      <div class="img">
        <img :src="staticUrl+'foot_logo.png'" alt>
        <img :src="staticUrl+'foot_icon.jpg'" alt>
      </div>
      <div class="link">
        <a href="/privacy">隱私政策</a>
        <a href="/service">服務條款</a>
        <a href="/about">關於我們</a>
        <a href="mailto:finance@kaguyajoy.com?subject=contact">聯繫我們</a>
      </div>
    </div>
    <div class="foot_center"></div>
    <div class="right">
      <p style="text-align: left;line-height: 22px;">
        <img :src="staticUrl+'foot_12.png'" alt>
        ※本遊戲情節涉性、暴力情節，依遊戲軟體分級管理辦法分類為輔12級
        <br>※本遊戲為免費使用，遊戲內另提供購買虛擬遊戲幣、物品等付費服務
        <br>※請注意使用時間，避免沉迷
      </p>
      <p>© 2022 KAGUYA TECHNOLOGY LIMITED</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'footBar',
  data () {
    return {}
  },
  computed:{
    ...mapGetters(['staticUrl']),
  }
}
</script>

<style lang="scss">

</style>
