<template>
  <div class="dialog_wrap"  :class="isMobile?'pop_mobile':'pop_pc'"  @click="closeDialog">
  <div class="award-dialog question" @click.stop="noTouch">
    <div class="dialog-close" @click="closeDialog"></div>
    <div class="award-content question"  v-if="answerIndex<0" >
        <div class="award-question-txt title">問題{{ qindex+1 | showQuestionIndex }}</div>
        <div class="award-question-txt content">{{ questions[qindex] }}</div>
        <div class="award-question-page">{{ (qindex+1)+"/"+questions.length }}</div>
        <div class="award-question-btns">
          <div class="award-question-ok" @click="nextQuestion(true)"></div>
          <div class="award-question-cancel" @click="nextQuestion(false)"></div>
        </div>
    </div>
    <div class="award-content question" v-else>
      <div class="answer-wrap">
       <div class="answer-hero-wrap"><div class="answer-hero" :class="'answer-hero-'+(answerIndex+1)"></div></div>
       <div class="answer-content">
        <div class="answer-title"> {{ answers[answerIndex].title }}</div>
        <div class="answer-cn">{{ answers[answerIndex].content }}</div>
       </div>
      </div>
       <div class="answer-btns">
        <a :href="links.facebook" target="_blank" class="answer-btn-facebook answer-btn"></a>
        <a :href="links.google" target="_blank" class="answer-btn-google answer-btn"></a>
        <a href="javascript:void(0)" @click="showProp(5)" class="answer-btn-prop answer-btn"></a>
       </div>

    </div>
  </div>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'AwardQuestion',
  props: {
  },
  computed: {
    ...mapGetters([
      'links', 'isMobile'
    ]),
  },
  data () {
    return {
      qindex: 0,
      rightNum: 0,
      questions: [
        '初入仙門，你是否會將掌門說的所有話都奉為行為準則，事事遵從？',
        '當你發現，你的同盟在修煉旁門左道，你是否會向掌門告發他？',
        '你在山間修煉意外發現掌門苦求不得的法寶，你是否會向掌門獻寶？',
        '你修煉多時，小有成就，一位異性仙友想你表白，希望能與你結成道侶，你是否同意？',
        '你潛心修煉，即將突破天劫，而收到訊息，你的宗門正在遭受圍攻，你是否會選擇回去營救？',
        '天下遭遇劫難，需要你捨棄最愛之人，才能拯救天下，你是否會選擇捨棄你最愛之人？'
      ],
      answerIndex: -1,
      answers: [
        { title: '一念成魔', content: '掌門在修煉過程容易為自己的執念所掌控，孤注一擲，不給其他可能性留有迴旋餘地，由而墮入魔道，以自己的方式開啟尋道之旅。' },
        { title: '逍遙道人', content: '掌門在修煉過程，不會拘泥一物，既不會為凡物所染，也不會過分沉浸于自我，經常化身雲遊道士，幫助世人除妖伏魔，為百姓所稱讚。故而能廣結好友，四海之內，遍地知己。' },
        { title: '一念成仙', content: '掌門一心向道，通曉世間之事本就無常，變化才是永恆，真正達到天人合一。以超于常人的領悟力，窺得天道。移形換影，遁光飛行， 無所不能。' },
        { title: '天選掌門', content: '掌門資質，機緣尚可，深的長老們的賞識，被當做下一代繼承人來培養。對於丹火煉製法寶有著獨特的見解，在今後修煉中定有神助。' },
        { title: '赤貧道人', content: '掌門一心為他人，經常會選擇犧牲自我，成就他人，但過度的自我犧牲，只會迷失自我，而失去修道之心。掌門，偶爾也要多為自己著想，只有強大自我，才能幫助他人。' },
        { title: '修真小白', content: '你在修真過程中一直謹受前輩教誨，過於在乎教條規章，在渡劫時容易為人間七情所困，很難達到突破。但平淡是真，獲得普通人的幸福，和道侶過上平淡的日子也不失為一種選擇。' },
        { title: '六道之主', content: '掌門踏遍六道輪迴，一切，生滅，無常皆為浮雲。六道輪回奈不誤，菩提三世書罪惡，不恨不癡。苦海無邊，回頭怎是岸？入了輪回，踏盡六道，天下眾生為何芸？' }
      ]
    }
  },
  mounted () {
    document.getElementsByTagName('body')[0].classList.add('noscroll');
  },
  beforeDestroy () {
    document.getElementsByTagName('body')[0].classList.remove('noscroll');
  },
  methods: {
    ...mapActions(['ActReward']),
    noTouch () {

    },
    closeDialog () {
      this.$emit('onClose', true)
    },
    nextQuestion (isRight) {
      if (isRight) {
        this.rightNum++
      }
      if (this.qindex === 5) {
        this.showAnswer()
      } else {
        this.qindex++
      }
    },
    showAnswer () {
      if (this.rightNum === 0) {
        this.answerIndex = 6
      } else if (this.rightNum >= 6) {
        this.answerIndex = 5
      } else {
        this.answerIndex = this.rightNum - 1
      }
      this.ActReward({ code_type: 5 }).then(res => {
        console.log(res)
      })
    },
    showProp () {
      this.$emit('onProp', true)
    }
  }
}
</script>

<style lang="scss">

</style>
