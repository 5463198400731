const state = {
  basicData: {
    CopyrightInfo: "Copyright © 2018-2020 GAME FORCE ONLINE CO., LTD",
  },
  staticUrl: window.staticUrl,
}

const getters = {
  basicData: state => state.basicData,
  staticUrl: state => state.staticUrl,
}

const mutations = {
}

const actions = {
}

export default {
  state,
  getters,
  mutations,
  actions
}
