<template>
    <div class="mobile-account-doc" >
            <div class="account-title" >{{ title }}</div>
            <div class="account-content">
                    <p  class="txt1" >
                        <span class="txt2">尊敬的</span><span  class="txt3">會員</span><span class="txt2">，您好！在您開始下一步有關本遊戲的遊戲</span><span  class="txt3">帳號刪除</span><span class="txt2">流程前，我們先為您做出如下特別說明：</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span  class="txt3">刪除帳號</span><span class="txt2">為不可恢復的操作，</span><span  class="txt3">帳號刪除</span><span class="txt2">後您將無法再以本</span><span  class="txt3">帳號</span><span class="txt2">登錄和使用我們提供的遊戲產品或服務，請您謹慎操作。</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span class="txt2">您成功</span><span  class="txt3">刪除</span><span class="txt2">本遊戲的遊戲</span><span  class="txt3">帳號</span><span class="txt2">後，我們將對您的個人資訊進行刪除或匿名化處理</span><span  class="txt3">，</span><span class="txt2">您</span><span  class="txt3">的遊戲</span><span class="txt2">日誌資訊將被保留</span><span>3</span><span class="txt2">個月的時間，且此前已關聯該遊戲</span><span  class="txt3">帳號</span><span class="txt2">的相關產品與服務將不再關聯。</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span class="txt2">若您經過慎重考慮後仍決定</span><span  class="txt3">刪除</span><span class="txt2">本遊戲</span><span  class="txt3">帳號</span><span class="txt2">的，請您務必先行仔細閱讀和充分理解本《遊戲</span><span  class="txt3">帳號刪除</span><span class="txt2">協議》（“本協議”），並同意本協議全部內容，本協議由您與</span><span  class="txt3">本公司</span><span class="txt2">遊戲服務提供方（以下稱“我們”）共同締結，本協議具有合同效力。您按照我們的</span><span  class="txt3">刪除</span><span class="txt2">操作流程開始</span><span  class="txt3">刪除</span><span class="txt2">流程的，或者您通過主動點擊或勾選的方式同意本</span><span  class="txt3">刪除</span><span class="txt2">協議並開始下一步操作的，均視為您已經同意遵守本協議全部內容。</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span class="txt2">一、遊戲</span><span  class="txt3">帳號刪除</span><span class="txt2">後您承擔的後果</span><span></span>
                    </p>
                    <p  class="txt1" >
                        <span class="txt2">您充分理解並同意：您自願承擔您的遊戲</span><span  class="txt3">帳號刪除</span><span class="txt2">所（可能）產生的所有後果，前述後果包括但不限於：</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span>1. </span><span class="txt2">您將無法登錄</span><span>/</span><span class="txt2">使用</span><span>/</span><span class="txt2">繼續使用與該遊戲</span><span  class="txt3">帳號</span><span class="txt2">相關的所有產品或服務，且該</span><span  class="txt3">帳號</span><span class="txt2">無法恢復，即使您在</span><span  class="txt3">帳號刪除</span><span class="txt2">後以相同的手機號碼在前述</span><span  class="txt3">帳號</span><span class="txt2">所在伺服器再次向我們申請註冊</span><span  class="txt3">本公司帳號</span><span class="txt2">，該</span><span  class="txt3">帳號</span><span class="txt2">將被默認為全新的用戶。</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span>2. </span><span class="txt2">遊戲</span><span  class="txt3">帳號刪除</span><span class="txt2">後，您曾通過該</span><span  class="txt3">帳號</span><span class="txt2">登錄、使用該遊戲</span><span  class="txt3">帳號</span><span class="txt2">相關產品及服務下的所有內容、資訊、數據、記錄將會被刪除或匿名化處理，前述內容、資訊、數據、記錄被刪除或匿名化處理後您將無法再檢索、訪問、傳輸、獲取、繼續使用、複製和找回，也無權要求我們找回。該等內容、資訊、數據或記錄包括但不限於：</span><span></span>
                    </p>
                    <p  class="txt1" >
                        <span class="txt2">（</span><span>1</span><span class="txt2">）</span> <span class="txt2">該</span><span  class="txt3">帳號</span><span class="txt2">下您的個人資訊，包括手機號碼、實名資訊、密碼密保和其他個人資訊；</span><span></span>
                    </p>
                    <p  class="txt1" >
                        <span class="txt2">（</span><span>2</span><span class="txt2">）</span> <span class="txt2">該</span><span  class="txt3">帳號</span><span class="txt2">下您曾使用過的所有遊戲相關的產品及服務，包括但不限於頭像、</span><span  class="txt3">暱稱</span><span class="txt2">、遊戲</span><span  class="txt3">帳號</span><span class="txt2">內角色及形象、與遊戲</span><span  class="txt3">帳號</span><span class="txt2">成長升級相關的所有數據（包括但不限於經驗值、榮譽值、聲望值、稱號等）、虛擬道具、郵件、</span><span  class="txt3">儲值</span><span class="txt2">和消費記錄、聊天記錄、排名等；</span><span></span>
                    </p>
                    <p  class="txt1" >
                        <span class="txt2">（</span><span>3</span><span class="txt2">）</span> <span class="txt2">該</span><span  class="txt3">帳號</span><span class="txt2">下的其他所有內容、資訊、數據、記錄。</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span>3. </span><span class="txt2">遊戲</span><span  class="txt3">帳號刪除</span><span class="txt2">後，我們將會對該</span><span  class="txt3">帳號</span><span class="txt2">下的全部權益（如有）做清除處理。據此，我們特別提示您，在您</span><span  class="txt3">刪除</span><span class="txt2">本遊戲</span><span  class="txt3">帳號</span><span class="txt2">前請您妥善處理、結清您遊戲內的相關資產和收益。您理解並同意：通過</span><span  class="txt3">刪除</span><span class="txt2">遊戲</span><span  class="txt3">帳號</span><span class="txt2">的方式放棄該</span><span  class="txt3">帳號</span><span class="txt2">在遊戲相關的產品及服務使用期間已產生但尚未消耗完畢的權益及未來可能產生的預期利益；您同意：不會要求我們恢復或賠償該等權益或預期利益。該等權益或預期利益包括但不限於：</span><span></span>
                    </p>
                    <p  class="txt1" >
                        <span class="txt2">（</span><span>1</span><span class="txt2">）</span> <span class="txt2">該</span><span  class="txt3">帳號</span><span class="txt2">下尚未使用的網路遊戲虛擬貨幣、虛擬道具等虛擬物品、資產、</span><span  class="txt3">儲值</span><span class="txt2">積分、等級等；</span><span></span>
                    </p>
                    <p  class="txt1" >
                        <span class="txt2">（</span><span>2</span><span class="txt2">）</span> <span class="txt2">該</span><span  class="txt3">帳號</span><span class="txt2">下您的各類資格、會員權益（包括但不限於遊戲月卡等）、連續包月權利等；</span><span></span>
                    </p>
                    <p  class="txt1" >
                        <span class="txt2">（</span><span>3</span><span class="txt2">）</span> <span class="txt2">該</span><span  class="txt3">帳號</span><span class="txt2">下您已產生但未消耗完畢的其他遊戲收益或未來預期的遊戲收益權益；</span><span></span>
                    </p>
                    <p  class="txt1" >
                        <span class="txt2">（</span><span>4</span><span class="txt2">）</span> <span class="txt2">該</span><span  class="txt3">帳號</span><span class="txt2">下您的各類身份權益；</span><span></span>
                    </p>
                    <p  class="txt1" >
                        <span class="txt2">（</span><span>5</span><span class="txt2">）</span> <span class="txt2">該</span><span  class="txt3">帳號</span><span class="txt2">下您已經購買但未到期的服務內容；</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span>4. </span><span class="txt2">本遊戲</span><span  class="txt3">帳號</span><span class="txt2">一旦</span><span  class="txt3">刪除</span><span class="txt2">，您與我們簽署的有關本遊戲的用戶協議、其他權利義務性檔即告終止。但上述協議的終止並不能夠減輕或免除您在協議期間內應根據相關法律法規、相關協議、規則等（可能）需要承擔的責任包括但不限於可能產生的違約責任、損害賠償責任及履約義務等。</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span>5. </span><span class="txt2">如您的</span><span  class="txt3">帳號</span><span class="txt2">曾因違反國家相關法律法規和</span><span>/</span><span class="txt2">或您與我們簽署的有關使用本遊戲服務的相關協議、規則（以下稱“用戶協議”）等相關規定，而被我們採取</span><span  class="txt3">封帳號</span><span class="txt2">及（或）其他處理措施的，為了維護您和其他相關權利人的合法權益所必須，在您遊戲</span><span  class="txt3">帳號刪除</span><span class="txt2">後我們可能會對與該</span><span  class="txt3">帳號</span><span class="txt2">違規行為有關的遊戲數據保留</span><span>1</span><span class="txt2">年。在保留期限內，我們除將該數據用於維護您和其他相關權利人的合法權益外，不會用於其他目的。</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span>6. </span><span class="txt2">其他因</span><span  class="txt3">帳號刪除</span><span class="txt2">（可能）產生的後果。</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span class="txt2">二、</span><span  class="txt3">刪除</span><span class="txt2">遊戲</span><span  class="txt3">帳號</span><span class="txt2">所應具備的條件</span><span></span>
                    </p>
                    <p  class="txt1" >
                        <span class="txt2">在您向我們申請</span><span>/</span><span class="txt2">繼續申請</span><span  class="txt3">刪除</span><span class="txt2">遊戲</span><span  class="txt3">帳號</span><span class="txt2">之前，為保障您的</span><span  class="txt3">帳號</span><span class="txt2">安全和相關權益，您需先行檢查，並確保和承諾您申請</span><span  class="txt3">刪除</span><span class="txt2">的遊戲</span><span  class="txt3">帳號</span><span class="txt2">已經同時滿足以下條件，這些條件包括但不限於：</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span>1. </span><span class="txt2">該遊戲</span><span  class="txt3">帳號</span><span class="txt2">為您本人合法擁有並登錄使用，且符合用戶協議及相關說明規則中有關遊戲</span><span  class="txt3">帳號</span><span class="txt2">的規範。</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span>2. </span><span class="txt2">該</span><span  class="txt3">帳號</span><span class="txt2">在申請</span><span  class="txt3">刪除</span><span class="txt2">前及申請</span><span  class="txt3">刪除</span><span class="txt2">期間沒有未處理完畢的糾紛，包括但不限於投訴舉報、被投訴舉報、國家機關調查、仲裁、訴訟等。</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span>3. </span><span class="txt2">該</span><span  class="txt3">帳號</span><span class="txt2">在申請</span><span  class="txt3">刪除</span><span class="txt2">前及申請</span><span  class="txt3">刪除</span><span class="txt2">期間未處於被檢查、被限制的狀態。</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span>4. </span><span class="txt2">該</span><span  class="txt3">帳號</span><span class="txt2">在最近</span><span>1</span><span class="txt2">個月內無異常登錄記錄，無</span><span  class="txt3">帳號</span><span class="txt2">敏感操作，該遊戲</span><span  class="txt3">帳號</span><span class="txt2">處於安全及正常使用狀態，無其他安全風險。</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span>5. </span><span class="txt2">該</span><span  class="txt3">帳號</span><span class="txt2">內相關遊戲資產和收益已結清或得到妥善處理，特別地，負資產需要結清。請您妥善處理您的遊戲收益，若</span><span  class="txt3">刪除</span><span class="txt2">時未處理，視為您自願放棄該等遊戲收益。</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span>6. </span><span class="txt2">該</span><span  class="txt3">帳號</span><span class="txt2">內無任何未完成的訂單或交易，包括但不限於基於該遊戲</span><span  class="txt3">帳號</span><span class="txt2">產生的有關網路遊戲虛擬貨幣、虛擬道具、周邊商品等的訂單等。</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span>7. </span><span class="txt2">該</span><span  class="txt3">帳號</span><span class="txt2">內沒有已經發起但未完成的抽獎活動。</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span>8. </span><span class="txt2">該</span><span  class="txt3">帳號</span><span class="txt2">已解除特殊身份關係（如公會會長</span><span>/</span><span class="txt2">管理員等）。</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span>9. </span><span class="txt2">如您曾基於該</span><span  class="txt3">帳號</span><span class="txt2">開通了“自動訂閱”</span><span>/</span><span class="txt2">“自動續費”</span><span>/</span><span class="txt2">“連續包月”等包含自動扣費或同類型功能的服務，您應當確保事先取消該等功能和服務。</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span>10. </span><span class="txt2">不存在任何由於該等</span><span  class="txt3">刪除</span><span class="txt2">而導致的未了結的合同關係和</span><span>/</span><span class="txt2">或其他基於該</span><span  class="txt3">帳號</span><span class="txt2">的存在而產生的或維持的權利義務，以及我們認為</span><span  class="txt3">刪除</span><span class="txt2">該</span><span  class="txt3">帳號</span><span class="txt2">會由此產生未了結的權利義務而產生糾紛的情況。</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span>11. </span><span class="txt2">本遊戲官方公佈的其他條件。</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span class="txt2">上述問題您可首先嘗試自行處理，如您無法處理的或在處理過程中有任何疑惑的，可聯繫我們的客服協助處理。</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span class="txt2">三、如何</span><span  class="txt3">刪除</span><span class="txt2">遊戲</span><span  class="txt3">帳號</span><span class="txt2">？</span><span></span>
                    </p>
                    <p  class="txt1" >
                        <span>1. </span><span class="txt2">您需按照我們的官方管道提供的</span><span  class="txt3">刪除</span><span class="txt2">流程和操作指引，在</span><span  class="txt3">遊戲中</span><span class="txt2">相關功能設置頁面進行</span><span  class="txt3">刪除</span><span class="txt2">操作。</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span>2. </span><span class="txt2">您已經同意本</span><span  class="txt3">刪除</span><span class="txt2">協議的全部內容，同時，您已經檢查與確認滿足了本協議第二條的要求。</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span>3. </span><span class="txt2">我們需要特別說明的是：即使我們判斷您符合</span><span  class="txt3">帳號刪除</span><span class="txt2">條件，也是基於您的承諾（即您自身確保遊戲</span><span  class="txt3">帳號</span><span class="txt2">符合上述</span><span  class="txt3">刪除</span><span class="txt2">條件的承諾）進行的形式審查，仍由您自身對遊戲</span><span  class="txt3">帳號</span><span class="txt2">符合上述</span><span  class="txt3">刪除</span><span class="txt2">條件承擔保證責任，我們對此不承擔相應的法律責任。</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span>4</span><span>. </span><span class="txt2">您理解並同意，在您申請</span><span  class="txt3">刪除帳號</span><span class="txt2">期間，如我們需要對相關交易、投訴或其他與您的</span><span  class="txt3">帳號</span><span class="txt2">相關的事項進行核實的，或者我們有其他合理的理由（包括但不限於投訴、舉報、訴訟、仲裁、國家有權機關調查等）的，我們有權凍結您的</span><span  class="txt3">帳號刪除</span><span class="txt2">申請並暫停為您提供</span><span  class="txt3">刪除</span><span class="txt2">服務而無需另行得到您的同意。</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span class="txt2">四、其他</span><span></span>
                    </p>
                    <p  class="txt1" >
                        <span>1. </span><span class="txt2">本協議為用戶協議（“主協議”）組成部分，本協議未盡事宜適用主協議。本協議與主協議不一致的，以本協議為准。</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span>2</span><span>. </span><span class="txt2">本協議的成立、生效、履行、解釋及糾紛解決，適用本約之解釋以香港特別行政區法令為準據法</span><span class="txt2">為準</span><span class="txt2">。</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span>3</span><span>. </span><span class="txt2">若您和我們之間因本協議發生任何糾紛或爭議，首先應友好協商解決；協商不成的，您同意將糾紛或爭議提交至香港特別行政區法院為第一審管轄法院仲裁解決，仲裁是終局的，對雙方均具有效力。</span><span></span>
                    </p>

                    <p  class="txt1" >
                        <span>4</span><span>. </span><span class="txt2">聯繫我們：如在</span><span  class="txt3">帳號刪除</span><span class="txt2">過程中有任何問題您無法處理的或在處理過程中有任何疑惑的，可聯繫我們的客服協助處理。</span><span></span>
                    </p>
            </div>
    </div>
</template>

<script>

export default {
  name: 'accountDoc',
  data () {
    return {
      title: '遊戲帳號刪除協議',
      content: ''
    }
  },
  mounted () {

  },
  created () {
  },
  methods: {
  }
}
</script>

<style lang="scss">
  @import "@/assets/mobile/account_doc.scss";
</style>
