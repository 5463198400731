<template>
  <div class="dialog_wrap" :class="isMobile?'pop_mobile':'pop_pc'" @click="closeDialog">
    <div class="dialog-video">
      <div class="dialog-video-close" @click="closeDialog"></div>
      <div class="dialog-video-box" @click.stop="noTouch">
        <video
          ref="myVideo"
          :poster="poster"
          :src="src"
          :controls="controls"
          :autoplay="autoplay"
          @timeupdate="timeupdate"
          controlslist="nodownload"
          class="video-box"
        >
        </video>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'VideoPlay',
  /**
   * @param poster 展示图
   * @param src 视频资源
   * @param controls 是否显示控件
   */
  props: {
    poster: {
      type: String,
      required: false,
      default: ""
    },
    src: {
      type: String,
      required: true
    },
    controls: {
      type: Boolean,
      required: false,
      default: true
    },
    autoplay: {
      type: String,
      required: false,
      default: "autoplay"
    }
  },
  computed: mapState({
    isMobile: (state) => state.act.isMobile,
  }),
  data () {
    return {
      videoState: true, // 视频播放状态
      studyTime: {
        currentTime: 0, // 当前已学时长
        duration: 0 // 总时长
      },
      timer: {}, // 定时器
      pauseTimer: {} // 暂停定时器
    }
  },
  mounted () {
     document.getElementsByTagName('body')[0].classList.add('noscroll');
    // 监听视频播放
    this.$refs.myVideo.addEventListener("play", () => {
      console.log("video is playing");
      this.openTimer();
    });
    // 监听视频暂停
    this.$refs.myVideo.addEventListener("pause", () => {
      console.log("video is stop");
      this.closeTimer();
    });
  },
  beforeDestroy () {
    document.getElementsByTagName('body')[0].classList.remove('noscroll');
  },
  methods: {
    noTouch () {

    },
    // 开启定时器
    openTimer() {
      this.timer = setInterval(() => {
        this.$emit("videoStudyTime", this.studyTime);
      }, 1000);
    },
    // 关闭定时器
    closeTimer() {
      clearInterval(this.timer);
      this.$emit("videoStudyTime", this.studyTime);
    },
    // 关闭暂停定时器
    closePauseTimer() {
      clearInterval(this.pauseTimer);
    },
    // 获取当前播放位置
    timeupdate(e) {
      this.studyTime.currentTime = e.target.currentTime;
      this.studyTime.duration = e.target.duration ? e.target.duration : 0;
    },
    closeDialog () {
      this.$emit('onClose', true)
    },
    doCopy: function (val) {
      this.$copyText(val).then(function (e) {
        console.log('Copied=' + e.text)
      }, function (e) {
        console.log('Can not copy', e.text)
      })
    }
  },
  watch: {}
}
</script>

<style lang="scss">
.dialog-video-box{
  display: initial;
  .video-box {
    box-sizing: border-box;
    border: 0;
    display: block;
    width: 100%;
    height: 100%;
    outline: none !important;
  }
  .isShow {
    //进度条
    video::-webkit-media-controls-timeline {
      display: none;
    }
  }
  video::-webkit-media-controls-play-button {
    visibility: hidden;
  }
  .operate-btn {
    display: block;
    width: 60px;
    height: 60px;
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
  }
  .operate-btn:hover {
    opacity: 0.8;
  }
  .fade-out {
    opacity: 0;
  }
}
</style>
