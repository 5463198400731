import { render, staticRenderFns } from "./AwardSuccess.vue?vue&type=template&id=77509d08&"
import script from "./AwardSuccess.vue?vue&type=script&lang=js&"
export * from "./AwardSuccess.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../80/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports