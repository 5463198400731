<template>
  <div class="dialog_wrap"  :class="isMobile?'pop_mobile':'pop_pc'"  @click="closeDialog">
    <div class="act-login-dialog" @click.stop="noTouch">
    <div class="act-login-close" @click="closeDialog"></div>
      <div class="act-login-content">
        <div class="act-login-title">
          <div class="act-login-title-txt"></div>
        </div>
        <div class="act-login-inputs">
          <div class="mobile-form">
              <el-select class="area-select" v-model="mobileAreaCode" @change="changeAreaCode" size="medium" placeholder="請選擇" v-if="!isMobile">
                <el-option
                  v-for="item in mobilePres"
                  :key="'pres-'+item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <select v-model="mobileAreaCode"  @change="changeAreaCode" v-else>
                <option v-for="item in mobilePres"
                  :key="'pres-'+item.value"
                  :value="item.value">{{ item.label }}</option>
              </select>
              <div class="mobile-input-wrap"><input class="mobile-input" type="tel"  :placeholder="mobileHolder" v-model="mobileNum" /></div>
          </div>
          <div class="login-checkbox">
            <input type="checkbox" value="" @change="checkboxChange">我同意個人資料的收集及接收獎勵簡訊,<a :href="isMobile?'/privacy/doc':'/privacy'" target="_blank">隱私協議</a>
          </div>
          <!-- <div class="code-form">
            <input class="code-input" type="tel" maxlength="6" placeholder="請輸入驗證碼" v-model="mobileCode" />
            <div class="code-btn" @click="sendCode">{{ sendSec>0?sendSec+'秒後再試':'獲取驗證碼' }}</div>
          </div> -->
        </div>
        <div class="act-login-btns"><div class="act-login-btn-ok ani" @click="tickBook"></div></div>
        <div class="act-login-props">
          <div class="act-login-prop-item" v-for="(item,index) in props" v-bind:key="'prop-'+index">
              <div class="act-login-prop-icon" :class="item.icon"></div>
              <div class="act-login-prop-name">{{ item.name }}</div>
              <div class="act-login-prop-num">{{ item.num }}</div>
            </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import utils from '@/utils/utils'

export default {
  name: 'ActLogin',
  props: {
    inviteUserId: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters([
      'userInfo',
      'isMobile'
    ])
  },
  watch: {
    mobileNum (value) {
      if (value.length > 0) {
        this.mobileNum = value.replace(/[^0-9]/, '')
        switch (this.mobileAreaCode) {
          case '+886':
            if (value[0] !== '9') {
              this.mobileNum = ''
            }
            if (value.length > 9) {
              this.mobileNum = value.substr(0, 9)
            }
            break;
          case '+852':
            if (value.length > 8) {
              this.mobileNum = value.substr(0, 8)
            }
            break;
          case '+853':
            if (value[0] !== '6') {
              this.mobileNum = ''
            }
            if (value.length > 8) {
              this.mobileNum = value.substr(0, 8)
            }
            break;
        }
      }
    }
  },
  data () {
    return {
      mobilePres: [{
        value: '+886',
        label: '+886(台灣)'
      }, {
        value: '+852',
        label: '+852(香港)'
      }, {
        value: '+853',
        label: '+853(澳門)'
      }],
      props: [
        { icon: 'prop-1', name: '暗香疏影', num: '*1' },
        { icon: 'prop-2', name: '綁玉', num: '*66' },
        { icon: 'prop-3', name: '仙王參', num: '*1' },
        { icon: 'prop-4', name: '金幣', num: '*3888' },
        { icon: 'prop-5', name: '尋仙令', num: '*5' }
      ],
      mobileAreaCode: '+886',
      mobileHolder: '9xxxxxxxx(共9位)',
      mobileNum: '',
      mobileCode: '',
      sendSec: 0,
      isCheck: false,
      lockAppoint: false,
    }
  },
  created () {
    document.getElementsByTagName('body')[0].classList.add('noscroll');
  },
  beforeDestroy () {
    document.getElementsByTagName('body')[0].classList.remove('noscroll');
  },
  methods: {
    ...mapActions(['ActLogin', 'ActReservation', 'ActCode', 'ActAppoint', 'ActCodeList']),
    changeAreaCode () {
      switch (this.mobileAreaCode) {
        case '+886':
          this.mobileHolder = '9xxxxxxxx(共9位)'
          break;
        case '+852':
          this.mobileHolder = 'xxxxxxxx(共8位)'
          break;
        case '+853':
          this.mobileHolder = '6xxxxxxx(共8位)'
          break;
      }
      this.mobileNum = ''
    },
    noTouch () {

    },
    closeDialog () {
      this.$emit('onClose', true)
    },
    checkboxChange (e) {
      this.isCheck = e.target.checked
    },
    tickBook () {
      if (this.mobileNum.length < 8 || this.mobileNum.length > 9) {
        this.showError('請輸入正確手機號')
        return;
      }
      // this.mobileCode = this.mobileCode.trim()
      // if (this.mobileCode.length !== 6) {
      //   this.showError('請輸入6位的驗證碼')
      //   return;
      // }
      if (!this.isCheck) {
        this.showError('請確認隱私協議選項內容')
        return;
      }

      if (this.lockAppoint) {
        return 
      }
      let mobile = this.mobileAreaCode + this.mobileNum;

      let isFacebook = false;
      let isGoogle = false;
      let isTwitter = false;
      let isTiktok = false;
      let utmSource = '';
      let os = utils.getSystemInfo()
      if (typeof (this.$route.query) !== 'undefined' && typeof (this.$route.query['utm_source']) !== 'undefined') {
        if (this.$route.query['utm_source'] === 'facebook') {
          isFacebook = true
        } else if (this.$route.query['utm_source'] === 'google') {
          isGoogle = true;
        } else if (this.$route.query['utm_source'] === 'twitter') {
          isTwitter = true;
        } else if (this.$route.query['utm_source'] === 'tiktok') {
          isTiktok = true;
        }
        utmSource = this.$route.query['utm_source'];
      }

      this.lockAppoint = true
      this.ActAppoint({ mobile: mobile, utm_source: utmSource, os: os }).then((res) => {
        this.lockAppoint = false
        if (res.is_first_appoint === 1) {
          if (isFacebook) {
            window.fbq('track', 'Schedule', { mobile: mobile }, { eventID: mobile });
          }
          if (isGoogle) {
            window.gtag('event', 'conversion', { 'send_to': 'AW-10946335345/7VUzCLbuv4MYEPGkz-Mo' });
          }
          if (isTwitter) {
            window.twq('event', 'tw-ocxth-oczqb', { conversion_id: mobile });
          }
          if (isTiktok) {
            window.ttq.track('AddToWishlist', { event_id: mobile }) 
          }
        }
        this.ActReservation({ mobile: mobile })
        this.$router.push({ name: 'reservationSuccess', query: this.$route.query })
      }).catch((err) => {
        this.lockAppoint = false
        console.log(err)
        this.showSuccess('預約失敗')
      })
      // this.ActLogin({ mobile: mobile, code: this.mobileCode }).then(() => {
      //   this.ActCodeList().then(res => {
      //     var isAppoint = false
      //     if (res.list.length > 0) {
      //       for (var i = 0; i < res.list.length; i++) {
      //         if (res.list[i].code_type === 1) {
      //           isAppoint = true
      //         }
      //       }
      //     }
      //     if (!isAppoint) {
      //       this.doBook()
      //     } else {
      //       this.$router.push({ name: 'reservationOk' })
      //     }
      //   })
      // }).catch(() => {
      //   this.showSuccess('登陸失敗')
      // })
    }
  }
}
</script>

<style lang="scss">

</style>
