<template>
  <div class="dialog_wrap"  :class="isMobile?'pop_mobile':'pop_pc'" @click="closeDialog">
  <div class="award-dialog" @click.stop="noTouch">
    <div class="dialog-close" @click="closeDialog"></div>
    <div class="award-content">
      <div class="award-title"><div class="award-title-bg"></div></div>
      <ul class="award-ul">
        <li class="award-li" v-for="(item,index) in list" v-bind:key="'li-'+index">
          <div class="award-code-wrap">{{ item.code_type|awardCodeType }} <span class="award-code">{{item.code }}</span></div> <div class="award-copy"  @click="doCopy(item.code)"></div>
        </li>
      </ul>
      <div class="award-tip" v-if="list.length">{{ tip }}</div>
    </div>
  </div>
</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'AwardList',
  props: {
  },
  computed: mapState({
    links: (state) => state.act.links,
    isMobile: (state) => state.act.isMobile,
  }),
  data () {
    return {
      list: [],
      tip: '獎勵有效期截止至：2023年1月31日23:59'
    }
  },
  mounted () {
    document.getElementsByTagName('body')[0].classList.add('noscroll');
    this.getAwardList();
  },
  beforeDestroy () {
    document.getElementsByTagName('body')[0].classList.remove('noscroll');
  },
  methods: {
    ...mapActions(['ActCodeList']),
    noTouch () {

    },
    closeDialog () {
      this.$emit('onClose', true)
    },
    getAwardList () {
      this.ActCodeList().then(res => {
        this.list = res.list
      }).catch((err) => {
        console.log(err)
      })
    },
    doCopy: function (val) {
      let that = this
      this.$copyText(val).then(function (e) {
        that.showSuccess('複製成功');
      }, function (e) {
        that.showSuccess('複製失敗');
      })
    }
  }
}
</script>

<style lang="scss">

</style>
